<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section data-role="paragraph" style="visibility: visible">
        <section data-role="paragraph" style="visibility: visible">
          <section
            data-role="title"
            data-tools="135编辑器"
            data-id="122632"
            style="visibility: visible"
          >
            <section
              style="
                margin: 10px auto;
                display: flex;
                justify-content: center;
                visibility: visible;
              "
            >
              <section
                style="display: flex; justify-content: center; visibility: visible"
              >
                <section style="flex-shrink: 0; visibility: visible">
                  <section
                    style="
                      width: 6px;
                      height: 100%;
                      background-color: rgb(45, 118, 191);
                      border-radius: 5px;
                      box-sizing: border-box;
                      overflow: hidden;
                      visibility: visible;
                    "
                  >
                    <br style="visibility: visible" />
                  </section>
                </section>
                <section
                  style="padding: 3px 5px; box-sizing: border-box; visibility: visible"
                >
                  <section
                    style="
                      font-size: 16px;
                      color: rgb(45, 118, 191);
                      text-align: center;
                      letter-spacing: 1.5px;
                      background-color: rgb(242, 247, 255);
                      padding: 5px 15px;
                      box-sizing: border-box;
                      visibility: visible;
                    "
                  >
                    <strong
                      data-brushtype="text"
                      style="
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        line-height: 1.56em;
                        visibility: visible;
                      "
                      ><p style="visibility: visible">
                        <strong
                          data-brushtype="text"
                          style="
                            letter-spacing: 1.5px;
                            font-size: 16px;
                            line-height: 1.56em;
                            visibility: visible;
                          "
                          >实我风险核验为安保公司规避用人风险</strong
                        >
                      </p></strong
                    >
                  </section>
                </section>
                <section style="flex-shrink: 0; visibility: visible">
                  <section
                    style="
                      width: 6px;
                      height: 100%;
                      background-color: rgb(45, 118, 191);
                      border-radius: 5px;
                      box-sizing: border-box;
                      visibility: visible;
                    "
                  >
                    <br style="visibility: visible" />
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section
            data-role="title"
            data-tools="135编辑器"
            data-id="129433"
            style="visibility: visible"
          >
            <section
              style="
                margin: 10px auto;
                display: flex;
                justify-content: flex-start;
                visibility: visible;
              "
            >
              <section style="visibility: visible">
                <section
                  style="display: flex; justify-content: flex-start; visibility: visible"
                >
                  <section
                    style="
                      font-size: 16px;
                      letter-spacing: 1.5px;
                      color: rgb(255, 255, 255);
                      background-color: rgb(156, 215, 121);
                      border-radius: 25px;
                      padding: 0px 10px;
                      box-sizing: border-box;
                      transform: rotate(-5deg);
                      visibility: visible;
                    "
                  >
                    <section
                      data-role="title"
                      data-tools="135编辑器"
                      data-id="128759"
                      style="visibility: visible"
                    >
                      <section
                        style="
                          margin: 10px auto;
                          display: flex;
                          justify-content: center;
                          visibility: visible;
                        "
                      >
                        <section
                          style="width: 30px; box-sizing: border-box; visibility: visible"
                          data-width="30px"
                        >
                          <img
                            data-width="100%"
                            data-src="@/assets/images/news/05/01.gif"
                            style="
                              display: block;
                              vertical-align: inherit;
                              box-sizing: border-box;
                              visibility: visible !important;
                              max-width: 100% !important;
                              width: 100% !important;
                              height: auto !important;
                            "
                            width="100%"
                            data-ratio="1"
                            data-w="363"
                            data-index="2"
                            src="@/assets/images/news/05/01.gif?wxfrom=5&amp;wx_lazy=1"
                            class="__bg_gif"
                            _width="100%"
                            data-order="0"
                            alt="图片"
                            data-fail="0"
                          />
                        </section>
                      </section>
                    </section>
                    <span
                      data-original-title=""
                      title=""
                      data-num="3"
                      style="visibility: visible"
                    ></span>
                  </section>
                </section>
                <p
                  style="
                    font-size: 16px;
                    color: rgb(90, 184, 255);
                    background-color: rgb(229, 249, 255);
                    padding: 6px 15px;
                    margin: -5px 0px 0px 10px;
                    box-sizing: border-box;
                    text-indent: 2em;
                    visibility: visible;
                  "
                >
                  <strong data-brushtype="text" style="visibility: visible"
                    >武汉作为国家超大城市，社会安全问题是作为城市治理的重要内容之一，而保安公司正是守护这座城市的重要力量之一。</strong
                  >
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible"><br style="visibility: visible" /></p>
        <p style="text-indent: 2em; visibility: visible">
          <span
            style="
              font-size: 14px;
              color: rgb(89, 89, 89);
              letter-spacing: normal;
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >中城特卫保安集团是一家专业从事保安服务的企业，公司拥有一支经验丰富、业务精湛、训练有素的保安队伍，能够为客户提供全面、专业、优质的保安服务。公司的服务范围涵盖了商务、住宅、工厂、学校、医院等各</span
          ><span
            style="
              text-indent: 2em;
              caret-color: red;
              font-size: 14px;
              color: rgb(89, 89, 89);
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >类场所及活动，能够满足不同客户的需求。</span
          >
        </p>
        <p style="visibility: visible"><br style="visibility: visible" /></p>
        <p style="visibility: visible">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.4691151919866444"
            data-src="@/assets/images/news/05/02.png"
            data-w="599"
            style="
              margin: 0px;
              padding: 0px;
              cursor: pointer;
              color: rgb(0, 0, 0);
              caret-color: red;
              text-decoration-thickness: initial;
              background-color: rgb(255, 255, 255);
              font-size: 19px;
              text-indent: 2em;
              vertical-align: inherit;
              max-inline-size: 100%;
              box-sizing: border-box;
              font-family: 仿宋;
              visibility: visible !important;
              overflow-wrap: break-word !important;
              outline: none 0px !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
            "
            data-index="3"
            src="@/assets/images/news/05/02.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
        <p><br /></p>
      </section>
      <section data-tools="135编辑器" data-id="124616">
        <section style="margin: 10px auto; display: flex; align-items: center">
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section style="flex-shrink: 0; padding: 0 5px; box-sizing: border-box">
            <section style="width: 25px; box-sizing: border-box">
              <img
                data-width="100%"
                data-src="@/assets/images/news/05/03.gif"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="1.1626984126984128"
                data-w="252"
                data-index="4"
                src="@/assets/images/news/05/03.gif?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-indent: 2em">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >在与中城特卫保安集团相关领导交流过程中发现，集团在员工从入职招聘、人员审核和人员管理方面存在一些困扰：</span
          >
        </p>
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >1.
            员工招聘过程中，人证合一的核查无法做到有效落实，导致员工在履职过程中出现意外，无法办理报销报销手续，给公司和员工均造成了损失；</span
          >
        </p>
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >2.
            按照《保安服务管理条例》，员工入职前须出具无犯罪证明。因核实无犯罪证明的真伪须投入大量的人力物力，不仅增加了公司的管理成本，也无法保证人员服务安全等级；</span
          >
        </p>
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >3. 对于大中型保安集团，无法高效、安全的统一管理员工的身份信息。</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="124616">
        <section style="margin: 10px auto; display: flex; align-items: center">
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section style="flex-shrink: 0; padding: 0 5px; box-sizing: border-box">
            <section style="width: 25px; box-sizing: border-box">
              <img
                data-width="100%"
                data-src="@/assets/images/news/05/03.gif"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="1.1626984126984128"
                data-w="252"
                data-index="5"
                src="@/assets/images/news/05/03.gif?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >在了解完客户在人员管理方面的痛点后，我们现场演示了人证核验机（简称小Q）,并重点对用户关注的以下三个核心功能进行了详细介绍：</span
          >
        </p>
        <p style="text-align: left; text-indent: 37px"><br /></p>
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.4166666666666667"
            data-src="@/assets/images/news/05/04.png"
            data-w="636"
            style="
              vertical-align: inherit;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="6"
            src="@/assets/images/news/05/04.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
        <p style="text-align: left; text-indent: 2em"><br /></p>
        <p style="text-align: left; text-indent: 2em">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >1.
            在员工入职人证合一校验方面，通过实时抓拍员工活体人脸图像，并读取身份证芯片信息进行核验，实现人证同一性验证，解决客户难以分辨人证是否匹配的问题。并且通过配套的实我认证小程序，在员工未携带身份证的情况下，通过小程序授权的二维码，也可以实现人证同一性功能。</span
          >
        </p>
        <p style="text-align: left; margin-left: 0px; text-indent: 2em">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >2.
            当员工入职身份核查方面，前端设备进行身份核验，若识别为关注人员，设备会语音提示请核查；在互联网平台可以查看到该人员的脱敏身份信息及相关预警标签，在公安内网平台可以查看到该人员的全量身份信息，并显示详细关注人员类型；实现与社会大联动，核查用户身份，预防安全隐患。</span
          >
        </p>
        <p style="text-indent: 2em">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >3.
            提供基于互联网SAAS平台数据可以接入客户自己的系统，进行私有化部署及交付，做到数据统一治理、信息统一调度、人员统一管理。</span
          >
        </p>
        <p style="text-indent: 2em"><br /></p>
      </section>
      <section data-tools="135编辑器" data-id="124616">
        <section style="margin: 10px auto; display: flex; align-items: center">
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section style="flex-shrink: 0; padding: 0 5px; box-sizing: border-box">
            <section style="width: 25px; box-sizing: border-box">
              <img
                data-width="100%"
                data-src="@/assets/images/news/05/03.gif"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="1.1626984126984128"
                data-w="252"
                data-index="7"
                src="@/assets/images/news/05/03.gif?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="width: 50%; box-sizing: border-box; max-width: 50% !important"
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                height: 3px;
                border-top: 1px solid rgb(41, 108, 212);
                border-bottom: 1px solid rgb(255, 193, 73);
                box-sizing: border-box;
                max-width: 100% !important;
                overflow: hidden;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-indent: 2em">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >人证核验机核查系统是由前端智能核验设备与互联网、公安网两个中心管理平台组成。设备采集数据后，通过互联网、局域网、公安网进行传输，数据接入汇聚并进行数据治理后归库存储，数据支撑应用功能调用建立业务实操流程。提供“跨域信任、跨域互通”的可信数字身份认证服务。</span
          >
        </p>
        <p style="text-indent: 2em"><br /></p>
        <p><br /></p>
      </section>
      <section data-role="paragraph" draggable="true">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.4102141680395387"
            data-src="@/assets/images/news/05/05.png"
            data-w="607"
            style="
              vertical-align: inherit;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="8"
            src="@/assets/images/news/05/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="607px"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 37px; text-autospace: ideograph-numeric">
          <br />
        </p>
        <p style="text-align: left; text-indent: 37px; text-autospace: ideograph-numeric">
          <span
            style="
              font-size: 14px;
              color: #595959;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >管理规范、严格的保安公司可以最大程度给政府、企业、市民带来安全保障。人证核验机（简称小Q）系统可以协助安保公司在人员综合管理方面提质增效，助力安保公司形成管理的核心竞争力。</span
          >
        </p>
        <p><br /></p>
      </section>
    </section>
    
  </div>
</template>
