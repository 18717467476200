<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.549074074074074"
            data-src="@/assets/images/news/02/01.png"
            data-w="1080"
            style="
              vertical-align: inherit;
              visibility: visible !important;
              width: 100% !important;
              height: auto !important;
            "
            data-index="1"
            src="@/assets/images/news/02/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="131862" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            background-color: rgb(251, 251, 255);
            padding: 13px;
            box-sizing: border-box;
            visibility: visible;
          "
        >
          <section
            style="display: flex; justify-content: flex-start; visibility: visible"
          >
            <section
              style="
                width: 25%;
                border-top: 1px solid rgb(14, 115, 220);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 25% !important;
                visibility: visible;
              "
              data-width="25%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              text-align: justify;
              line-height: 1.75em;
              letter-spacing: 1.5px;
              font-size: 14px;
              color: rgb(51, 51, 51);
              background-color: transparent;
              padding: 13px 0px 0px;
              box-sizing: border-box;
              visibility: visible;
            "
          >
            <section
              style="background-color: transparent; visibility: visible"
              data-autoskip="1"
            >
              <p
                style="
                  text-align: center;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: rgb(0, 0, 0);
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  font-family: 宋体;
                  visibility: visible;
                "
              >
                <span style="color: rgb(0, 112, 192); visibility: visible"
                  ><strong style="visibility: visible"
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(53, 50, 59);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >文化和旅游部&nbsp;公安部<br
                        style="visibility: visible"
                      />关于加强电竞酒店管理中未成年人保护<br
                        style="visibility: visible"
                      />工作的通知&nbsp;</span
                    ></strong
                  ></span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 24.5px;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >各省、自治区、直辖市文化和旅游厅（局）、公安厅（局）,新疆生产建设兵团文化体育广电和旅游局、公安局：</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >近年来，电竞酒店行业快速发展，在满足群众需求、扩大消费等方面起到了积极作用，同时也存在接待未成年人等引发社会关注的问题。为认真贯彻落实《中华人民共和国未成年人保护法》有关规定，根据最有利于未成年人原则，切实加强电竞酒店管理中未成年人保护工作，促进行业健康有序发展，现就有关事项通知如下。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >一、提高政治站位，充分认识加强电竞酒店管理中未成年人保护工作的重要意义</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（一）提高政治站位。党中央、国务院历来高度重视未成年人保护工作，习近平总书记和有关中央领导同志多次对未成年人保护工作作出重要指示批示。加强未成年人保护工作，促进未成年人健康成长，是贯彻落实党中央、国务院决策部署并回应社会关切的重要举措，是各级政府管理部门肩负的重要职责。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二）强化责任担当。各级文化和旅游行政部门、公安机关应当提高政治站位，主动担当作为，坚持问题导向，不断创新监管方式，既要坚决管住电竞酒店违规接待未成年人问题，又要秉持包容审慎原则，合理引导电竞酒店行业健康有序发展，做到守土有责、守土尽责。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >二、加强底线管理，严禁电竞酒店违规接待未成年人</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（三）明确业态属性。本通知所称的电竞酒店是指通过设置电竞房向消费者提供电子竞技娱乐服务的新型住宿业态，包括所有客房均为电竞房的专业电竞酒店和利用部分客房开设电竞房区域的非专业电竞酒店。电竞酒店每间电竞房的床位数不得超过6张，计算机数量和入住人员不得超过床位数。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（四）严禁电竞酒店违规接待未成年人。专业电竞酒店和非专业电竞酒店的电竞房区域，属于不适宜未成年人活动的场所。电竞酒店经营者应当遵守《中华人民共和国未成年人保护法》等有关法律法规，不得允许未成年人进入专业电竞酒店和非专业电竞酒店的电竞房区域。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >三、强化主体责任，严格落实未成年人保护规定</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（五）设置禁入标志。专业电竞酒店经营者应当在酒店入口处的显著位置悬挂未成年人禁入标志；非专业电竞酒店经营者应当在相近楼层集中设置电竞房并划定电竞房区域，在电竞房区域入口处的显著位置悬挂未成年人禁入标志。电竞酒店经营者应当在前台显著位置和客房管理系统明示电竞房区域分布图。鼓励非专业电竞酒店经营者对电竞房区域进行物理隔离、电梯控制，防止未成年人擅自进入。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（六）履行告知义务。电竞酒店经营者应当在消费者预定、入住等环节明确告知其电竞房区域不接待未成年人；通过电子商务平台等开展客房预定的，应当以显著方式提示消费者电竞房区域不接待未成年人。电子商务平台经营者应当核验电竞酒店提示信息。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（七）落实“五必须”规定。电竞酒店非电竞房区域接待未成年人入住时,经营者应当严格落实“五必须”规定:</span
                ><span style="color: #c00000"
                  ><strong
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 1.71em;
                        font-style: normal;
                        text-decoration: none solid #35323b;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >必须查验入住未成年人身份并如实登记</span
                    ></strong
                  ></span
                ><span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >;必须询问未成年人父母或者其他监护人的联系方式并记录备案;必须询问同住人员身份关系等情况并记录备案;<strong
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 1.71em;
                        font-style: normal;
                        text-decoration: none solid #35323b;
                        color: #c00000;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >必须加强安全巡查和访客管理</span
                    ></strong
                  ><span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.71em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >,</span
                  >预防对未成年人的不法侵害;必须立即向公安机关报告可疑情况,并及时联系未成年人的父母或者其他监护人，并同时采取相应安全保护措施。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（八）实施网络安全技术措施。电竞酒店经营者应当依法制定信息网络安全管理制度和应急处置预案，实施互联网安全保护技术措施。电竞酒店经营者应当设置禁止未成年人登录计算机、消费时长提示等功能，并通过网络技术措施服务提供者向文化和旅游行政部门提供电竞房分布、设置禁止未成年人登录功能以及阻断登录情况等可查询信息。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（九）<strong
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 1.71em;
                        font-style: normal;
                        text-decoration: none solid #35323b;
                        color: #c00000;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >实施图像采集技术措施</span
                    ></strong
                  >。电竞酒店经营者应当按照有关规定，在大厅、前台、通道、电竞房区域主要出入口等公共区域内的合理位置安装图像采集设备并设置采集区域提示标识，加强检查值守，发现有未成年人违规进入电竞房区域的，要及时劝阻并联系其父母或者其他监护人。<strong
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 1.71em;
                        font-style: normal;
                        text-decoration: none solid #35323b;
                        color: #c00000;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >图像采集信息应当依法留存，不得不当披露、传播，并在文化和旅游行政部门等部门检查电竞房时提供查询。</span
                    ></strong
                  ></span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十）建立日常巡查制度。电竞酒店经营者应当建立日常巡查制度，发现有未成年人违规进入、未实名登记擅自进入等违法行为的，应当立即制止并分别向所在地县级文化和旅游行政部门、公安机关报告。文化和旅游行政部门、公安机关等有关部门有权依法对辖区内电竞酒店的电竞房实施监督检查，电竞酒店经营者应当配合，不得拒绝、阻挠。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >四、加强协同监管，形成未成年人保护合力</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十一）建立协同监管机制。地方各级文化和旅游行政部门、公安机关应当会同相关部门，建立电竞酒店未成年人保护协同监管机制，加强信息通报、线索移送、执法联动等工作，引导督促经营者严格落实实名登记、设置未成年人禁入标志、禁止违规接待未成年人等要求，依法查处违规经营行为。公安机关在工作中发现电竞酒店经营者违规接待未成年人的，及时通报文化和旅游行政部门依法查处；文化和旅游行政部门在工作中发现电竞酒店经营者未落实实名登记及“五必须”规定的，及时通报公安机关依法查处。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十二）严格文化和旅游市场行政处罚。电竞酒店经营者违规接待未成年人或者未设置未成年人禁入标志的，由文化和旅游行政部门依照《中华人民共和国未成年人保护法》第一百二十三条予以处罚。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十三）严格治安管理行政处罚。电竞酒店非电竞房区域接待未成年人入住，或者接待未成年人和成年人共同入住时，未询问父母或者其他监护人的联系方式、入住人员的身份关系等有关情况的，由公安机关依照《中华人民共和国未成年人保护法》第一百二十二条予以处罚。电竞酒店未取得特种行业许可证，擅自经营旅馆业的，由公安机关依照《中华人民共和国治安管理处罚法》第五十四条予以处罚，并对非法经营行为予以取缔。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十四）加强行业自律。电竞酒店有关行业协会应当加强行业自律，制定行业规范，开展培训教育，探索开展本领域的信用评价、服务等级评定工作，引导经营者严格落实《中华人民共和国未成年人保护法》有关规定。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十五）加强社会监督。社会公众可以依法向文化和旅游行政部门、公安机关反映电竞酒店违规接待未成年人等情况；鼓励和支持电竞酒店入住人员向文化和旅游行政部门、公安机关举报电竞酒店违规接待未成年人等线索。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >各省级文化和旅游行政部门应当会同公安机关部署开展电竞酒店摸底排查工作，摸清辖区内电竞酒店的企业名称、类型、住所、电竞房数量、计算机台数等情况，并于2023年10月31日前分别报送文化和旅游部、公安部。</span
                >
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <section data-tools="135编辑器" data-id="131857">
          <section style="text-align: center; margin: 10px auto">
            <section style="background-color: #f5f8ff">
              <section
                style="
                  padding: 30px 25px 15px 25px;
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #35323b;
                  background: transparent;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em">
                    <span
                      style="
                        font-size: 15px;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-style: normal;
                        color: #35323b;
                        text-decoration: none solid #35323b;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      ><span
                        style="
                          font-weight: 400;
                          letter-spacing: 1.5px;
                          line-height: 1.64em;
                          font-style: normal;
                          color: #35323b;
                          text-decoration: none solid #35323b;
                          font-size: 15px;
                          font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        "
                        >《通知》指出，加强未成年人保护工作，促进未成年人健康成长，是贯彻落实党中央、国务院决策部署并回应社会关切的重要举措，是各级政府管理部门肩负的重要职责，要提高政治站位，充分认识加强电竞酒店管理中未成年人保护工作的重要意义。</span
                      ></span
                    >
                  </p>
                  <p style="text-indent: 2em">
                    <span
                      style="
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 1.64em;
                        font-style: normal;
                        color: #35323b;
                        text-decoration: none solid #35323b;
                        font-size: 15px;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >《通知》明确，履行告知义务、落实“五必须”、实施网络安全技术措施、图像采集技术措施、建立日常巡查制度等防范未成年人进入电竞房的系列监管制度。</span
                    >
                  </p>
                </section>
              </section>
              <section
                style="display: flex; justify-content: flex-end; align-items: flex-end"
              >
                <section
                  style="
                    width: 0px;
                    height: 1px;
                    border-bottom: 22px solid #0e73dc;
                    border-left: 22px solid transparent;
                    overflow: hidden;
                    box-sizing: border-box;
                  "
                >
                  <br />
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-tools="135编辑器" data-id="131859">
          <section style="margin: 10px auto; text-align: right">
            <section style="font-size: 40px; letter-spacing: 1.5px; color: #f7f8ff">
              <strong data-brushtype="text" style="caret-color: red">NATION</strong><br />
            </section>
            <section
              style="
                background-color: #f7f8ff;
                padding: 0 25px 35px 0px;
                margin-top: -30px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  transform: translate(0px, -20px);
                  -webkit-transform: translate(0px, -20px);
                  -moz-transform: translate(0px, -20px);
                  -o-transform: translate(0px, -20px);
                "
              >
                <section
                  style="
                    width: 5px;
                    height: 34px;
                    background-color: #0e73dc;
                    margin-right: 12px;
                    overflow: hidden;
                    box-sizing: border-box;
                  "
                >
                  <br />
                </section>
                <section style="font-size: 20px; letter-spacing: 1.5px; color: #374278">
                  <p style="text-align: left; text-indent: 0em">
                    <strong data-brushtype="text"
                      >人证核验机落实监管未成年入住风险</strong
                    >
                  </p>
                </section>
              </section>
              <section
                style="
                  padding: 0px 0 0 25px;
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #555562;
                  background: transparent;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em">
                    人证核验机实我风险核验系统充分利用人工智能、大数据、物联网、云计算等新技术，真正做到人员、证件、照片三者统一，实现“预约来访、身份核查、进出留痕、人像对应、分级授权管理、历史记录查询、流量分析统计、报表汇总”等功能。通过人防和技防相结合，简化登记流程，实现无纸化作业，<span
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        color: #555562;
                        text-decoration: none solid #555562;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >严格落实未成年人保护规定，</span
                    >同时提高访客进出效率，提升酒店企业形象。
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                  <p style="text-align: center; text-indent: 0em">
                    <img
                      data-width="173px"
                      data-src="@/assets/images/news/02/02.png"
                      style="
                        vertical-align: inherit;
                        box-sizing: border-box;
                        width: 173px !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      width="173"
                      data-ratio="1.1155234657039712"
                      data-w="277"
                      data-index="2"
                      src="@/assets/images/news/02/02.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      class=""
                      _width="173px"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-tools="135编辑器" data-id="131858">
          <section style="margin: 10px auto">
            <section>
              <section style="display: flex">
                <section style="flex-shrink: 0; transform-style: preserve-3d">
                  <section
                    style="
                      color: #ffffff;
                      background-color: #72c7e0;
                      padding: 3px 4px 3px 15px;
                      box-sizing: border-box;
                      transform: translateZ(10px);
                      -webkit-transform: translateZ(10px);
                      -moz-transform: translateZ(10px);
                      -o-transform: translateZ(10px);
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">▶ 01&nbsp;</strong></span
                    >
                  </section>
                  <section
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin: -6px 0 0;
                      padding: 0 2px 2px 0;
                      box-sizing: border-box;
                      transform: translateZ(5px);
                      -webkit-transform: translateZ(5px);
                      -moz-transform: translateZ(5px);
                      -o-transform: translateZ(5px);
                    "
                  >
                    <section
                      style="
                        width: 30px;
                        height: 10px;
                        background-color: #97dbf0;
                        overflow: hidden;
                        box-sizing: border-box;
                        transform: skewY(-10deg);
                        -webkit-transform: skewY(-10deg);
                        -moz-transform: skewY(-10deg);
                        -o-transform: skewY(-10deg);
                      "
                      data-width="50%"
                    >
                      <br />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    max-width: 100% !important;
                    background-color: #0e73dc;
                    display: flex;
                    width: 100%;
                    margin: 0 0 0 -30px;
                    align-items: center;
                    box-sizing: border-box;
                  "
                  data-width="100%"
                >
                  <section
                    style="
                      color: #ffffff;
                      text-align: left;
                      letter-spacing: 1.5px;
                      padding: 4px 10px 4px 50px;
                      box-sizing: border-box;
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">在线人证核验</strong></span
                    >
                  </section>
                </section>
              </section>
              <section
                style="
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #333333;
                  background-color: transparent;
                  padding: 10px 0 15px;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em"><br /></p>
                  <p style="text-indent: 2em">
                    <span
                      style="
                        font-size: 15px;
                        color: #595959;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-family: 微软雅黑;
                      "
                      >现场抓拍现场活体人脸图像，</span
                    ><span style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                      >读取二代居民身份证芯片信</span
                    ><span style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                      >息，将活体人脸图像和平台</span
                    ><span style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                      >进行核验，实现人证同一性</span
                    ><span style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                      >验证，</span
                    ><span
                      style="
                        color: #595959;
                        font-size: 15px;
                        background-color: transparent;
                        caret-color: red;
                        font-family: 微软雅黑;
                      "
                      >避免未成年冒用他人身份证入住风险。</span
                    >
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                </section>
              </section>
            </section>
            <section>
              <section style="display: flex">
                <section style="flex-shrink: 0; transform-style: preserve-3d">
                  <section
                    style="
                      color: #ffffff;
                      background-color: #72c7e0;
                      padding: 3px 4px 3px 15px;
                      box-sizing: border-box;
                      transform: translateZ(10px);
                      -webkit-transform: translateZ(10px);
                      -moz-transform: translateZ(10px);
                      -o-transform: translateZ(10px);
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">▶ 02&nbsp;</strong></span
                    >
                  </section>
                  <section
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin: -6px 0 0;
                      padding: 0 2px 2px 0;
                      box-sizing: border-box;
                      transform: translateZ(5px);
                      -webkit-transform: translateZ(5px);
                      -moz-transform: translateZ(5px);
                      -o-transform: translateZ(5px);
                    "
                  >
                    <section
                      style="
                        width: 30px;
                        height: 10px;
                        background-color: #97dbf0;
                        overflow: hidden;
                        box-sizing: border-box;
                        transform: skewY(-10deg);
                        -webkit-transform: skewY(-10deg);
                        -moz-transform: skewY(-10deg);
                        -o-transform: skewY(-10deg);
                      "
                      data-width="50%"
                    >
                      <br />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    max-width: 100% !important;
                    background-color: #0e73dc;
                    display: flex;
                    width: 100%;
                    margin: 0 0 0 -30px;
                    align-items: center;
                    box-sizing: border-box;
                  "
                  data-width="100%"
                >
                  <section
                    style="
                      color: #ffffff;
                      text-align: left;
                      letter-spacing: 1.5px;
                      padding: 4px 10px 4px 50px;
                      box-sizing: border-box;
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">无线核验能力</strong></span
                    >
                  </section>
                </section>
              </section>
              <section
                style="
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #333333;
                  background-color: transparent;
                  padding: 10px 0 15px;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em"><br /></p>
                  <p style="text-indent: 2em">
                    <span
                      style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                    ></span
                    ><span
                      style="
                        font-size: 15px;
                        color: #595959;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-family: 微软雅黑;
                      "
                      >支持无需携带身份证时进行人证核验。通过微信小程序（实我认证）注册网络身份证生成个人专属身份证二维码，在进行人证核验时出示专属二维码，设备可将二维码和现场抓拍活体人像提交至中华人民共和国公安部可信身份认证平台（CTID）进行核验，实现人证同一性验证。</span
                    ><span
                      style="font-size: 15px; color: #595959; font-family: 微软雅黑"
                    ></span>
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                </section>
              </section>
            </section>
            <section>
              <section style="display: flex">
                <section style="flex-shrink: 0; transform-style: preserve-3d">
                  <section
                    style="
                      color: #ffffff;
                      background-color: #72c7e0;
                      padding: 3px 4px 3px 15px;
                      box-sizing: border-box;
                      transform: translateZ(10px);
                      -webkit-transform: translateZ(10px);
                      -moz-transform: translateZ(10px);
                      -o-transform: translateZ(10px);
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">▶ 03&nbsp;</strong></span
                    >
                  </section>
                  <section
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin: -6px 0 0;
                      padding: 0 2px 2px 0;
                      box-sizing: border-box;
                      transform: translateZ(5px);
                      -webkit-transform: translateZ(5px);
                      -moz-transform: translateZ(5px);
                      -o-transform: translateZ(5px);
                    "
                  >
                    <section
                      style="
                        width: 30px;
                        height: 10px;
                        background-color: #97dbf0;
                        overflow: hidden;
                        box-sizing: border-box;
                        transform: skewY(-10deg);
                        -webkit-transform: skewY(-10deg);
                        -moz-transform: skewY(-10deg);
                        -o-transform: skewY(-10deg);
                      "
                      data-width="50%"
                    >
                      <br />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    max-width: 100% !important;
                    background-color: #0e73dc;
                    display: flex;
                    width: 100%;
                    margin: 0 0 0 -30px;
                    align-items: center;
                    box-sizing: border-box;
                  "
                  data-width="100%"
                >
                  <section
                    style="
                      color: #ffffff;
                      text-align: left;
                      letter-spacing: 1.5px;
                      padding: 4px 10px 4px 50px;
                      box-sizing: border-box;
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">重点关注人员核查</strong></span
                    >
                  </section>
                </section>
              </section>
              <section
                style="
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #333333;
                  background-color: transparent;
                  padding: 10px 0 15px;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em"><br /></p>
                  <p style="text-indent: 2em">
                    <span style="font-size: 15px"
                      ><span
                        style="
                          color: #595959;
                          letter-spacing: 1.5px;
                          line-height: 1.57em;
                          font-family: 微软雅黑;
                        "
                        >核验数据与全国关注人员数据进行碰撞，比中则返回核验人员的关注类别，</span
                      ><span
                        style="
                          color: #595959;
                          background-color: transparent;
                          caret-color: red;
                          font-family: 微软雅黑;
                        "
                        >数据实时鲜活，为使用单位获得更加安全的环境提供保障。</span
                      ></span
                    >
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                  <section data-tools="135编辑器" data-id="131858">
                    <section style="margin: 10px auto">
                      <section>
                        <section style="display: flex">
                          <section style="flex-shrink: 0; transform-style: preserve-3d">
                            <section
                              style="
                                color: #ffffff;
                                background-color: #72c7e0;
                                padding: 3px 4px 3px 15px;
                                box-sizing: border-box;
                                transform: translateZ(10px);
                                -webkit-transform: translateZ(10px);
                                -moz-transform: translateZ(10px);
                                -o-transform: translateZ(10px);
                              "
                            >
                              <span style="font-size: 14px"
                                ><strong data-brushtype="text">▶ 04</strong></span
                              >
                            </section>
                            <section
                              style="
                                display: flex;
                                justify-content: flex-end;
                                margin: -6px 0 0;
                                padding: 0 2px 2px 0;
                                box-sizing: border-box;
                                transform: translateZ(5px);
                                -webkit-transform: translateZ(5px);
                                -moz-transform: translateZ(5px);
                                -o-transform: translateZ(5px);
                              "
                            >
                              <section
                                style="
                                  width: 30px;
                                  height: 10px;
                                  background-color: #97dbf0;
                                  overflow: hidden;
                                  box-sizing: border-box;
                                  transform: skewY(-10deg);
                                  -webkit-transform: skewY(-10deg);
                                  -moz-transform: skewY(-10deg);
                                  -o-transform: skewY(-10deg);
                                "
                                data-width="50%"
                              >
                                <br />
                              </section>
                            </section>
                          </section>
                          <section
                            style="
                              max-width: 100% !important;
                              background-color: #0e73dc;
                              display: flex;
                              width: 100%;
                              margin: 0 0 0 -30px;
                              align-items: center;
                              box-sizing: border-box;
                            "
                            data-width="100%"
                          >
                            <section
                              style="
                                color: #ffffff;
                                text-align: left;
                                letter-spacing: 1.5px;
                                padding: 4px 10px 4px 50px;
                                box-sizing: border-box;
                              "
                            >
                              <span style="font-size: 14px"
                                ><strong data-brushtype="text">数据存储安全</strong></span
                              >
                            </section>
                          </section>
                        </section>
                        <section
                          style="
                            text-align: justify;
                            line-height: 1.75em;
                            letter-spacing: 1.5px;
                            font-size: 14px;
                            color: #333333;
                            background-color: transparent;
                            padding: 10px 0 15px;
                            box-sizing: border-box;
                          "
                        >
                          <section data-autoskip="1">
                            <p style="text-indent: 2em"><br /></p>
                            <p style="text-indent: 2em">
                              <span
                                style="
                                  color: #595959;
                                  letter-spacing: 1.5px;
                                  line-height: 1.57em;
                                  font-size: 15px;
                                  font-family: 微软雅黑;
                                "
                                >人证核验机自动记录持证通行人员信息，可在系统管理页面统计查询，数据可通过多种维度、形式展现。互联网无敏感数据，数据存储在专网内，有效避免信息泄露风险。</span
                              >
                            </p>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="height: 0px; overflow: hidden">
        <br />
      </section>
      <section data-tools="135编辑器" data-id="119902">
        <section style="margin: 10px auto">
          <section
            style="
              display: flex;
              justify-content: flex-end;
              margin: 0 0 -8px 0;
              padding: 0 40px 0 0;
              box-sizing: border-box;
            "
          >
            <section
              style="background-color: #ffffff; padding: 0 15px; box-sizing: border-box"
            >
              <section
                style="
                  width: 6px;
                  height: 15px;
                  border-left: 1px solid rgb(65, 151, 212);
                  border-right: 1px solid rgb(65, 151, 212);
                  box-sizing: border-box;
                  transform: skew(30deg);
                  overflow: hidden;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              border-width: 1px;
              border-style: solid;
              border-color: rgb(43, 139, 207);
              padding: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #445b6a;
                background: transparent;
              "
            >
              <p style="text-indent: 2em">
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >不按规定登记住宿旅客信息容易滋生违法犯罪，极易发生侵害未成年人合法权益的案事件、未成年人沉迷网络等问题。</span
                >
              </p>
              <p style="text-indent: 2em">
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >通过“三实五必须”加强监管，中码科技人证核验机牢守安全底线，促进行业健康有序发展，保障住宿人员、经营者的合法权益。</span
                >
              </p>
            </section>
          </section>
          <section>
            <section
              style="
                width: 10%;
                height: 7px;
                background-color: rgb(229, 248, 252);
                box-sizing: border-box;
                max-width: 10% !important;
                overflow: hidden;
              "
              data-width="10%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
    </section>
    
  </div>
</template>
