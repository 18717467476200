<template>
  <div>
    <ComHeader menuKey="news" :topY="1"></ComHeader>
    <img class="menu-box" src="@/assets/images/menu-bg.png" alt="" />
    <!-- main -->
    <div class="container news-detail animate__animated animate__slideInLeft">
      <component :is="currentComponent"></component>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";
import Article1 from "./article/1.vue";
import Article2 from "./article/2.vue";
import Article3 from "./article/3.vue";
import Article4 from "./article/4.vue";
import Article5 from "./article/5.vue";
import Article6 from "./article/6.vue";
import Article7 from "./article/7.vue";
import Article8 from "./article/8.vue";

export default defineComponent({
  components: {
    ComHeader,
    ComFooter,
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Article8,
  },

  setup() {
    const route = useRoute();
    let id = route.params.id;
    let currentComponent = ref("Article" + id);
    // console.log(id);
    // console.log(currentComponent);

    return {
      currentComponent,
      id,
    };
  },
});
</script>

<style lang="less" scoped>
.menu-box {
  width: 100%;
  height: 72px;
}
.news-detail {
  padding: 50px 0;
}
</style>
<style lang="less" scoped>
.mobile {
  .plan-detail {
    padding: 20px;
    .plan-item {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      .pain-wrap {
        flex-wrap: wrap;
        & > .pain-item {
          width: 100%;
          margin-bottom: 10px;
          font-size: 13px;
          .pain-item-title {
            font-size: 18px;
          }
        }
      }
      .advan-wrap {
        font-size: 15px;
        .advan-item {
          width: 100%;
          margin-bottom: 10px;
          padding: 40px 20px;
        }
      }
      .success-img-wrap {
        flex-wrap: wrap;
        .success-img-item {
          width: 48%;
        }
      }
    }
  }
}
</style>
