<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section data-role="paragraph" style="visibility: visible">
        <p style="text-align: center; visibility: visible">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.5675925925925925"
            data-src="@/assets/images/news/07/01.jpg"
            data-w="1080"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              visibility: visible !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
            "
            title="626.jpg"
            data-index="1"
            src="@/assets/images/news/07/01.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
        <p style="visibility: visible">
          <span style="font-size: 12px; color: rgb(127, 127, 127); visibility: visible"
            >图源封面新闻</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="104405" style="visibility: visible">
        <section
          itemtype="https://mp.weixin.qq.com/voc/Question"
          style="margin: 10px auto; text-align: left; visibility: visible"
        >
          <section style="visibility: visible">
            <section
              style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                text-align: right;
                visibility: visible;
              "
            >
              <section
                style="padding: 6px 8px 10px; box-sizing: border-box; visibility: visible"
                :style="{
                  background:
                    'url(' +
                    require('@/assets/images/news/07/02.png') +
                    ') center center / 100% no-repeat',
                }"
              >
                <section
                  data-brushtype="text"
                  style="
                    font-size: 16px;
                    letter-spacing: 1.5px;
                    color: rgb(255, 255, 255);
                    visibility: visible;
                  "
                >
                  <strong style="visibility: visible">&nbsp;</strong>
                </section>
              </section>
            </section>
          </section>
          <section
            itemprop="name"
            data-brushtype="text"
            style="
              font-size: 16px;
              letter-spacing: 1.5px;
              color: rgb(241, 137, 75);
              background-color: rgb(255, 247, 234);
              border-radius: 8px;
              padding: 5px 0px;
              margin: -27px 5px 0px 0px;
              text-align: left;
              box-sizing: border-box;
              visibility: visible;
            "
          >
            <strong style="visibility: visible"
              >“这是有史以来最棒的一届世界科幻大会”</strong
            >
          </section>
          <section
            style="
              display: flex;
              justify-content: flex-start;
              margin: 15px 0px -45px;
              visibility: visible;
            "
          >
            <section style="display: inline-block; visibility: visible">
              <section
                style="
                  padding: 6px 7px 10px 9px;
                  box-sizing: border-box;
                  visibility: visible;
                "
                :style="{
                  background:
                    'url(' +
                    require('@/assets/images/news/07/03.png') +
                    ') center center / 100% no-repeat',
                }"
              >
                <section
                  data-brushtype="text"
                  style="
                    font-size: 16px;
                    letter-spacing: 1.5px;
                    color: rgb(255, 255, 255);
                    visibility: visible;
                  "
                >
                  <br style="visibility: visible" />
                </section>
              </section>
            </section>
          </section>
          <section style="margin-right: 8px; visibility: visible">
            <section
              style="
                width: 0px;
                height: 1px;
                border-right: 35px solid transparent;
                border-left: 0px solid transparent;
                border-bottom: 20px solid rgb(255, 250, 242);
                margin-left: auto;
                box-sizing: border-box;
                overflow: hidden;
                visibility: visible;
              "
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            itemprop="suggestedAnswer"
            itemtype="https://mp.weixin.qq.com/voc/Answer"
            style="
              background-color: rgb(255, 250, 242);
              padding: 30px 20px;
              border-radius: 10px;
              margin-left: 5px;
              box-sizing: border-box;
              visibility: visible;
            "
          >
            <section
              itemprop="text"
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: rgb(114, 99, 90);
                background: transparent;
                visibility: visible;
              "
            >
              <p style="text-indent: 2.2142em; visibility: visible">
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 24.5px;
                    font-style: normal;
                    color: rgb(114, 99, 90);
                    text-decoration: none solid rgb(114, 99, 90);
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    visibility: visible;
                  "
                  >10月22日晚，第81届世界科幻大会在位于成都市郫都区的成都科幻馆圆满闭幕。</span
                >
              </p>
              <p style="text-indent: 2.2142em; visibility: visible">
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 24.5px;
                    font-style: normal;
                    color: rgb(114, 99, 90);
                    text-decoration: none solid rgb(114, 99, 90);
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    visibility: visible;
                  "
                  >世界科幻大会是世界上最受瞩目、历史最悠久、规模和影响力最大的科幻文化主题活动，此次是这一盛会首次走进中国，成都也因此成为亚洲第二个举办世界科幻大会的城市。</span
                >
              </p>
              <p style="text-indent: 2.2142em; visibility: visible">
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 24.5px;
                    font-style: normal;
                    color: rgb(114, 99, 90);
                    text-decoration: none solid rgb(114, 99, 90);
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    visibility: visible;
                  "
                  >在为期5天的时间里，超</span
                ><span style="color: rgb(192, 0, 0); visibility: visible"
                  ><strong style="visibility: visible"
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(114, 99, 90);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >2万</span
                    ></strong
                  ></span
                ><span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 24.5px;
                    font-style: normal;
                    color: rgb(114, 99, 90);
                    text-decoration: none solid rgb(114, 99, 90);
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    visibility: visible;
                  "
                  >名幻迷共聚成都，有来自全球<strong style="visibility: visible"
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(114, 99, 90);
                        color: rgb(192, 0, 0);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >35个</span
                    ></strong
                  >国家和地区的超<strong style="visibility: visible"
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(114, 99, 90);
                        color: rgb(192, 0, 0);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >1200名</span
                    ></strong
                  >嘉宾参会。来自全球各地的科幻作家、科学家、影视大咖、产业研究者、投资人和科幻爱好者跨界畅聊，拥抱科幻的“共生纪元”。</span
                >
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104088" style="visibility: visible">
        <section style="text-align: center; margin: 10px auto; visibility: visible">
          <section
            style="padding: 23px 0px 17px; box-sizing: border-box; visibility: visible"
            :style="{
              background:
                'url(' +
                require('@/assets/images/news/07/04.png') +
                ') center center / 20% no-repeat',
            }"
          >
            <section
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                visibility: visible;
              "
            >
              <section
                style="
                  width: 45px;
                  margin-left: -40px;
                  flex-shrink: 0;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <img
                  data-width="100%"
                  data-src="@/assets/images/news/07/05.png"
                  style="
                    vertical-align: inherit;
                    display: block;
                    box-sizing: border-box;
                    visibility: visible !important;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                  "
                  data-ratio="0.7205882352941176"
                  data-w="68"
                  data-index="2"
                  src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
              <section
                data-brushtype="text"
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: rgb(255, 161, 99);
                  margin-left: 10px;
                  visibility: visible;
                "
              >
                <strong style="visibility: visible">安全通行</strong>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104084">
        <section style="text-align: center; margin: 10px auto">
          <section style="margin-top: 40px">
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: rgb(255, 172, 123);
                border-radius: 50%;
                margin: 0px 0px -40px;
                box-sizing: border-box;
                transform: rotate(0deg);
                overflow: hidden;
              "
            >
              <br />
            </section>
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: rgb(194, 176, 254);
                border-radius: 50%;
                margin: 0px 0px -3px 30px;
                box-sizing: border-box;
                transform: rotate(0deg);
                overflow: hidden;
              "
            >
              <br />
            </section>
            <section
              style="
                border-width: 1px;
                border-style: solid;
                border-color: rgb(255, 179, 112);
                border-radius: 10px;
                padding: 5px;
                margin-left: 3px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 30px 10px 10px 20px;
                  border-radius: 10px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    padding-right: 10px;
                    text-align: justify;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                    box-sizing: border-box;
                  "
                >
                  <p style="text-indent: 2.2142em">
                    此次世界科幻大会具备规模大、参展人员多、流动性大等大型活动的特点，<span
                      style="background-color: transparent; caret-color: red"
                      >应2023年09月14日</span
                    ><span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        text-align: center;
                      "
                      >文化和旅游部公安部发布的</span
                    ><strong
                      ><span style="color: #c00000"
                        ><span
                          style="
                            background-color: transparent;
                            caret-color: red;
                            text-align: center;
                          "
                          >《</span
                        ><span
                          style="
                            background-color: transparent;
                            caret-color: red;
                            text-align: center;
                            letter-spacing: 1.5px;
                            font-size: 14px;
                            line-height: 1.71em;
                          "
                          >关于进一步加强大型营业性演出活动规范管理促进演出市场健康有序发展的通知》</span
                        ></span
                      ></strong
                    ><span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        text-align: center;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        line-height: 1.71em;
                      "
                      >，明确大型演出活动实行实名购票和实名入场制度，演出举办单位应当建立大型演出活动退票机制，面向市场公开销售的门票数量不得低于核准观众数量的85%。对其余15%的门票，应当在活动前24小时进行个人信息绑定，做到<strong
                        ><span
                          style="
                            background-color: transparent;
                            caret-color: red;
                            text-align: center;
                            letter-spacing: 1.5px;
                            font-size: 14px;
                            line-height: 1.71em;
                            color: #c00000;
                          "
                          >“实名绑定、实名入场”</span
                        ></strong
                      >。</span
                    >
                  </p>
                  <p style="text-indent: 2.2142em">
                    <span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        line-height: 1.64em;
                      "
                      >在科幻馆外，我单位配合成都市公安局郫都区分局健全完善安检点位建设，加强对大型演出活动的入场核查，现场核验人员门票一致性，做好智慧安防、社会面管控、加强票务管理等多项保障措施，有效检验方案预案的完整性、科学性、实效性，</span
                    ><span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        line-height: 1.64em;
                      "
                      >最高标准做好大会开幕前各项安保准备工作，为护航盛会顺利召开筑牢织密安全防护网。</span
                    ><span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        line-height: 1.71em;
                      "
                    ></span>
                  </p>
                  <p><br /></p>
                  <section
                    style="
                      width: 45px;
                      text-align: right;
                      box-sizing: border-box;
                      height: 0px;
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104083">
        <section style="text-align: center; margin: 10px auto">
          <section
            style="display: flex; justify-content: space-between; align-items: flex-start"
          >
            <section
              style="width: 48%; box-sizing: border-box; max-width: 48% !important"
              data-width="48%"
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 20px 20px 25px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    text-align: left;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                  "
                >
                  <p>日均人流量峰值1w人</p>
                </section>
              </section>
            </section>
            <section
              style="width: 48%; box-sizing: border-box; max-width: 48% !important"
              data-width="48%"
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 20px 20px 25px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    text-align: right;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                  "
                >
                  <p>日平均人流量8k人</p>
                </section>
              </section>
            </section>
          </section>
          <section
            style="
              margin-top: -40px;
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
            "
          >
            <section
              style="
                width: 29%;
                margin: auto;
                border-width: 5px;
                border-style: solid;
                border-color: rgb(255, 255, 255);
                border-radius: 50%;
                box-sizing: border-box;
                max-width: 29% !important;
              "
              data-width="29%"
            >
              <img
                class="rich_pages wxw-img"
                data-ratio="1"
                data-src="@/assets/images/news/07/06.jpg"
                data-w="568"
                data-width="100%"
                style="
                  display: block;
                  border-radius: 50%;
                  vertical-align: inherit;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-index="3"
                src="@/assets/images/news/07/06.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -35px;
            "
          >
            <section
              style="width: 48%; box-sizing: border-box; max-width: 48% !important"
              data-width="48%"
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 30px 20px 15px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    text-align: left;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                  "
                >
                  <p>单日票观众日均2k人</p>
                </section>
              </section>
            </section>
            <section
              style="width: 48%; box-sizing: border-box; max-width: 48% !important"
              data-width="48%"
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 30px 20px 15px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    text-align: right;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                  "
                >
                  <p>累计入场人次达4w人</p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p><br /></p>
      </section>
      <section data-tools="135编辑器" data-id="104087">
        <section style="text-align: left; margin: 10px auto">
          <section style="display: flex">
            <section style="display: flex">
              <section
                style="
                  width: 1px;
                  height: 100%;
                  background-color: rgb(252, 151, 91);
                  margin: 0px -20px 0px 20px;
                  box-sizing: border-box;
                  transform: rotate(0deg);
                  overflow: hidden;
                "
              >
                <br />
              </section>
            </section>
            <section style="flex: 1">
              <section>
                <section
                  style="
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                  "
                >
                  <section
                    style="
                      width: 35px;
                      background-color: #fff;
                      padding: 5px 0;
                      flex-shrink: 0;
                      box-sizing: border-box;
                    "
                  >
                    <img
                      data-width="100%"
                      data-src="@/assets/images/news/07/05.png"
                      style="
                        vertical-align: inherit;
                        display: block;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-ratio="0.7205882352941176"
                      data-w="68"
                      data-index="4"
                      src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      class=""
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </section>
                  <section style="font-size: 14px; letter-spacing: 1.5px; color: #f1894b">
                    <strong data-brushtype="text">安全性</strong>
                  </section>
                </section>
                <section
                  style="
                    background-color: #fffaf2;
                    padding: 10px 20px 15px 35px;
                    margin-top: 10px;
                    box-sizing: border-box;
                  "
                >
                  <section
                    data-autoskip="1"
                    style="
                      text-align: justify;
                      line-height: 1.75em;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      color: #72635a;
                      background: transparent;
                    "
                  >
                    <p>
                      <span
                        style="
                          color: #24292f;
                          font-size: 14px;
                          text-align: left;
                          text-decoration-thickness: initial;
                          display: inline !important;
                          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
                            'Helvetica Neue', sans-serif;
                        "
                        >身份验证可以确保只有经过认证的人员才能进入特定场所或访问敏感信息，从而最大限度地降低非法入侵、犯罪活动或未经授权的访问可能导致的风险。</span
                      >
                    </p>
                  </section>
                </section>
              </section>
              <section>
                <section
                  style="
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    margin-top: 10px;
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                  "
                >
                  <section
                    style="
                      width: 35px;
                      background-color: #fff;
                      padding: 5px 0;
                      flex-shrink: 0;
                      box-sizing: border-box;
                    "
                  >
                    <img
                      data-width="100%"
                      data-src="@/assets/images/news/07/05.png"
                      style="
                        vertical-align: inherit;
                        display: block;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-ratio="0.7205882352941176"
                      data-w="68"
                      data-index="5"
                      src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      class=""
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </section>
                  <section style="font-size: 14px; letter-spacing: 1.5px; color: #f1894b">
                    <strong>可识别性</strong>
                  </section>
                </section>
                <section
                  style="
                    background-color: #fffaf2;
                    padding: 10px 20px 15px 35px;
                    margin-top: 10px;
                    box-sizing: border-box;
                  "
                >
                  <section
                    data-autoskip="1"
                    style="
                      text-align: justify;
                      line-height: 1.75em;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      color: #72635a;
                      background: transparent;
                    "
                  >
                    <p>
                      <span
                        style="
                          color: #24292f;
                          font-size: 14px;
                          text-align: left;
                          text-decoration-thickness: initial;
                          display: inline !important;
                          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
                            'Helvetica Neue', sans-serif;
                        "
                        >身份验证可以帮助确定个人是谁，确保他们有资格参与特定活动或获得特定服务，这对于控制人员流动和管理参与者的身份信息非常重要，本次活动中采用了身份芯片核验，快速识别特邀嘉宾及会员身份。</span
                      >
                    </p>
                  </section>
                </section>
              </section>
              <section>
                <section
                  style="
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    margin-top: 10px;
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                  "
                >
                  <section
                    style="
                      width: 35px;
                      background-color: #fff;
                      padding: 5px 0;
                      flex-shrink: 0;
                      box-sizing: border-box;
                    "
                  >
                    <img
                      data-width="100%"
                      data-src="@/assets/images/news/07/05.png"
                      style="
                        vertical-align: inherit;
                        display: block;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-ratio="0.7205882352941176"
                      data-w="68"
                      data-index="6"
                      src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      class=""
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </section>
                  <section
                    style="
                      font-size: 14px;
                      letter-spacing: 1.5px;
                      color: #f1894b;
                      margin-left: 5px;
                    "
                  >
                    <strong data-brushtype="text">可追溯性</strong>
                  </section>
                </section>
                <section
                  style="
                    background-color: #fffaf2;
                    padding: 10px 20px 15px 35px;
                    margin-top: 10px;
                    box-sizing: border-box;
                  "
                >
                  <section
                    data-autoskip="1"
                    style="
                      text-align: justify;
                      line-height: 1.75em;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      color: #72635a;
                      background: transparent;
                    "
                  >
                    <p>
                      <span
                        style="
                          color: #24292f;
                          font-size: 14px;
                          text-align: left;
                          text-decoration-thickness: initial;
                          display: inline !important;
                          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
                            'Helvetica Neue', sans-serif;
                        "
                        >身份验证可以创建记录，记录个人的参与活动或访问特定场所的行为。在需要追踪和查明事件时，这些记录可以提供有价值的证据。</span
                      >
                    </p>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104085">
        <section
          itemtype="https://mp.weixin.qq.com/voc/Question"
          style="text-align: left; margin: 10px auto"
        >
          <section style="display: inline-block">
            <section
              style="padding: 6px 8px 10px; box-sizing: border-box"
              :style="{
                background:
                  'url(' +
                  require('@/assets/images/news/07/08.png') +
                  ') center center / 100% no-repeat',
              }"
            >
              <section
                data-brushtype="text"
                style="font-size: 16px; letter-spacing: 1.5px; color: #fff"
              >
                <strong>&nbsp;</strong>
              </section>
            </section>
          </section>
          <section
            style="
              font-size: 16px;
              letter-spacing: 1.5px;
              color: #f1894b;
              background-color: #fff7ea;
              border-radius: 8px;
              padding: 5px 0 5px 30px;
              margin: -27px 0 0 5px;
              box-sizing: border-box;
            "
          >
            <strong
              data-brushtype="text"
              itemprop="name"
              style="letter-spacing: 1.5px; font-size: 16px; line-height: 1.56em"
              >然而，在进行身份验证时，也需要注意安全性的问题：</strong
            >
          </section>
          <section
            style="display: flex; justify-content: flex-end; margin: 15px 0 -45px 0"
          >
            <section style="display: inline-block">
              <section
                style="padding: 6px 7px 10px 9px; box-sizing: border-box"
                :style="{
                  background:
                    'url(' +
                    require('@/assets/images/news/07/07.png') +
                    ') center center / 100% no-repeat',
                }"
              >
                <section
                  data-brushtype="text"
                  style="font-size: 16px; letter-spacing: 1.5px; color: #ffffff"
                >
                  <br />
                </section>
              </section>
            </section>
          </section>
          <section
            style="
              width: 0px;
              height: 1px;
              border-left: 35px solid transparent;
              border-right: 0px solid transparent;
              border-bottom: 20px solid rgb(255, 250, 242);
              margin-left: 8px;
              box-sizing: border-box;
              overflow: hidden;
            "
          >
            <br />
          </section>
          <section
            itemprop="suggestedAnswer"
            itemtype="https://mp.weixin.qq.com/voc/Answer"
            style="
              background-color: #fffaf2;
              padding: 30px 20px;
              border-radius: 10px;
              margin-right: 5px;
              box-sizing: border-box;
            "
          >
            <section
              itemprop="text"
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #72635a;
                background: transparent;
              "
            >
              <section data-role="list">
                <ol
                  data-v-md-line="11"
                  style="
                    list-style-type: decimal;
                    margin: 0px;
                    padding: 0 0 0 30px;
                    list-style-position: outside;
                  "
                  class="list-paddingleft-1"
                >
                  <li>
                    <p
                      data-v-md-line="11"
                      style="
                        border-width: 0px;
                        border-style: solid;
                        border-color: initial;
                        box-sizing: border-box;
                      "
                    >
                      隐私保护：在收集和处理个人身份信息时，必须遵守适用的隐私法规和最佳实践，确保个人身份信息的保密性和安全性。
                    </p>
                  </li>
                  <li>
                    <p
                      data-v-md-line="13"
                      style="
                        border-width: 0px;
                        border-style: solid;
                        border-color: initial;
                        box-sizing: border-box;
                      "
                    >
                      数据保护：存储和处理身份信息时，必须采取适当的安全措施，防止未经授权的访问、数据泄露或数据滥用。
                    </p>
                  </li>
                  <li>
                    <p
                      data-v-md-line="15"
                      style="
                        border-width: 0px;
                        border-style: solid;
                        border-color: initial;
                        box-sizing: border-box;
                      "
                    >
                      合法性：在进行身份验证时，必须遵守适用的法律和法规，确保合法性和合规性。
                    </p>
                  </li>
                </ol>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104088">
        <section style="text-align: center; margin: 10px auto">
          <section
            style="background-size: 36%; padding: 23px 0 17px 0; box-sizing: border-box"
            :style="{
              background:
                'url(' +
                require('@/assets/images/news/07/04.png') +
                ') center center / 20% no-repeat',
            }"
          >
            <section style="display: flex; justify-content: center; align-items: center">
              <section
                style="
                  width: 45px;
                  margin-left: -40px;
                  flex-shrink: 0;
                  box-sizing: border-box;
                "
              >
                <img
                  data-width="100%"
                  data-src="@/assets/images/news/07/05.png"
                  style="
                    vertical-align: inherit;
                    display: block;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-ratio="0.7205882352941176"
                  data-w="68"
                  data-index="7"
                  src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
              <section
                data-brushtype="text"
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: #ffa163;
                  margin-left: 10px;
                "
              >
                <strong>图片展示</strong>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104082" draggable="true">
        <section style="text-align: left; margin: 10px auto">
          <section
            style="
              display: flex;
              justify-content: left;
              align-items: center;
              margin-bottom: -19px;
            "
          >
            <section
              style="
                width: 50px;
                margin-left: 10px;
                flex-shrink: 0;
                box-sizing: border-box;
                height: 0px;
                overflow: hidden;
              "
            >
              <br />
            </section>
          </section>
          <section
            style="
              background-color: #fffaf2;
              padding: 45px 20px 30px;
              box-sizing: border-box;
            "
          >
            <section
              data-brushtype="text"
              style="font-size: 14px; letter-spacing: 1.5px; color: #73635a"
            >
              <p style="text-indent: 0em">
                <span
                  style="
                    background-color: transparent;
                    letter-spacing: 1.5px;
                    text-align: justify;
                    caret-color: red;
                  "
                  >本次共设立5个主通道，46台安检设备，分别位于科幻馆西一门、西三门、媒体中心、喜来登酒店主入口、次入口。</span
                >
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104081">
        <section style="text-align: left; margin: 10px auto">
          <section style="display: flex; justify-content: flex-end; margin-bottom: -23px">
            <section
              style="
                width: 40px;
                box-sizing: border-box;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              "
            >
              <img
                data-width="100%"
                data-src="@/assets/images/news/07/05.png"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="0.7205882352941176"
                data-w="68"
                data-index="8"
                src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="
              border-bottom: 6px solid #fef7ea;
              border-left: 3px solid #fef7ea;
              border-right: 6px solid #fef7ea;
              border-radius: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                border-radius: 10px;
                padding: 40px 20px 30px;
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #72635a;
                background: transparent;
                box-sizing: border-box;
              "
            >
              <p style="vertical-align: inherit">
                <img
                  class="rich_pages wxw-img"
                  data-ratio="1.3324074074074075"
                  data-src="@/assets/images/news/07/09.jpg"
                  data-w="1080"
                  style="
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-index="9"
                  src="@/assets/images/news/07/09.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104084" draggable="true">
        <section style="text-align: center; margin: 10px auto">
          <section style="margin-top: 40px">
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: rgb(255, 172, 123);
                border-radius: 50%;
                margin: 0px 0px -40px;
                box-sizing: border-box;
                transform: rotate(0deg);
                overflow: hidden;
              "
            >
              <br />
            </section>
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: rgb(194, 176, 254);
                border-radius: 50%;
                margin: 0px 0px -3px 30px;
                box-sizing: border-box;
                transform: rotate(0deg);
                overflow: hidden;
              "
            >
              <br />
            </section>
            <section
              style="
                border-width: 1px;
                border-style: solid;
                border-color: rgb(255, 179, 112);
                border-radius: 10px;
                padding: 5px;
                margin-left: 3px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 30px 10px 10px 20px;
                  border-radius: 10px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    padding-right: 10px;
                    text-align: justify;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                    box-sizing: border-box;
                  "
                >
                  <p>
                    <img
                      class="rich_pages wxw-img"
                      data-ratio="0.75"
                      data-src="@/assets/images/news/07/10.jpg"
                      data-w="1080"
                      style="
                        vertical-align: inherit;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-index="10"
                      src="@/assets/images/news/07/10.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </p>
                </section>
                <section
                  style="display: flex; justify-content: flex-end; margin-top: -20px"
                >
                  <section
                    style="
                      width: 45px;
                      box-sizing: border-box;
                      height: 0px;
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="">
        <p><br /></p>
      </section>
      <section data-tools="135编辑器" data-id="104081">
        <section style="text-align: left; margin: 10px auto">
          <section style="display: flex; justify-content: flex-end; margin-bottom: -23px">
            <section
              style="
                width: 40px;
                box-sizing: border-box;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              "
            >
              <img
                data-width="100%"
                data-src="@/assets/images/news/07/05.png"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="0.7205882352941176"
                data-w="68"
                data-index="11"
                src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="
              border-bottom: 6px solid #fef7ea;
              border-left: 3px solid #fef7ea;
              border-right: 6px solid #fef7ea;
              border-radius: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                border-radius: 10px;
                padding: 40px 20px 30px;
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #72635a;
                background: transparent;
                box-sizing: border-box;
              "
            >
              <p style="vertical-align: inherit">
                <img
                  class="rich_pages wxw-img"
                  data-ratio="0.75"
                  data-src="@/assets/images/news/07/11.jpg"
                  data-w="1080"
                  style="
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-index="12"
                  src="@/assets/images/news/07/11.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104084">
        <section style="text-align: center; margin: 10px auto">
          <section style="margin-top: 40px">
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: #ffac7b;
                border-radius: 50%;
                margin: 0px 0px -40px;
                overflow: hidden;
                box-sizing: border-box;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              "
            >
              <br />
            </section>
            <section
              style="
                width: 7px;
                height: 7px;
                background-color: #c2b0fe;
                border-radius: 50%;
                margin: 0px 0px -3px 30px;
                overflow: hidden;
                box-sizing: border-box;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              "
            >
              <br />
            </section>
            <section
              style="
                border-width: 1px;
                border-style: solid;
                border-color: rgb(255, 179, 112);
                border-radius: 10px;
                padding: 5px;
                margin-left: 3px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  background-color: #fffaf2;
                  padding: 30px 10px 10px 20px;
                  border-radius: 10px;
                  box-sizing: border-box;
                "
              >
                <section
                  data-autoskip="1"
                  style="
                    padding-right: 10px;
                    text-align: justify;
                    line-height: 1.75em;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #73635a;
                    background: transparent;
                    box-sizing: border-box;
                  "
                >
                  <p>
                    <img
                      class="rich_pages wxw-img"
                      data-ratio="0.75"
                      data-src="@/assets/images/news/07/12.jpg"
                      data-w="1080"
                      style="
                        vertical-align: inherit;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-index="13"
                      src="@/assets/images/news/07/12.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    />
                  </p>
                </section>
                <section
                  style="display: flex; justify-content: flex-end; margin-top: -20px"
                >
                  <section
                    style="
                      width: 45px;
                      height: 0px;
                      overflow: hidden;
                      box-sizing: border-box;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="104081">
        <section style="text-align: left; margin: 10px auto">
          <section style="display: flex; justify-content: flex-end; margin-bottom: -23px">
            <section
              style="
                width: 40px;
                box-sizing: border-box;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
              "
            >
              <img
                data-width="100%"
                data-src="@/assets/images/news/07/05.png"
                style="
                  vertical-align: inherit;
                  display: block;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-ratio="0.7205882352941176"
                data-w="68"
                data-index="14"
                src="@/assets/images/news/07/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
          <section
            style="
              border-bottom: 6px solid #fef7ea;
              border-left: 3px solid #fef7ea;
              border-right: 6px solid #fef7ea;
              border-radius: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                border-radius: 10px;
                padding: 40px 20px 30px;
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #72635a;
                background: transparent;
                box-sizing: border-box;
              "
            >
              <p style="vertical-align: inherit">
                <img
                  class="rich_pages wxw-img"
                  data-ratio="0.75"
                  data-src="@/assets/images/news/07/13.jpg"
                  data-w="1080"
                  style="
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-index="15"
                  src="@/assets/images/news/07/13.jpg?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <section data-tools="135编辑器" data-id="132240">
          <section style="margin: 10px auto">
            <section style="margin: 0 0 10px; display: flex; justify-content: center">
              <section style="display: flex; align-items: flex-end">
                <section>
                  <section
                    style="
                      font-size: 16px;
                      color: rgb(118, 145, 191);
                      text-align: center;
                      height: 0px;
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                  <section
                    style="
                      width: 100%;
                      border-top: 1px solid rgb(118, 145, 191);
                      box-sizing: border-box;
                      max-width: 100% !important;
                      height: 1px;
                      overflow: hidden;
                    "
                    data-width="100%"
                  >
                    <br />
                  </section>
                  <section style="font-size: 14px; color: #fbe0d7; text-align: center">
                    <section
                      data-role="splitline"
                      data-tools="135编辑器"
                      data-id="129630"
                    >
                      <section style="margin: 10px auto">
                        <section
                          style="
                            width: 100%;
                            max-width: 100% !important;
                            box-sizing: border-box;
                          "
                          data-width="100%"
                        >
                          <img
                            class="rich_pages wxw-img __bg_gif"
                            data-ratio="0.09590316573556797"
                            data-src="@/assets/images/news/07/14.gif"
                            data-w="1074"
                            data-width="100%"
                            style="
                              display: block;
                              vertical-align: inherit;
                              box-sizing: border-box;
                              max-width: 100% !important;
                              width: 100% !important;
                              height: auto !important;
                              visibility: visible !important;
                            "
                            data-index="16"
                            src="@/assets/images/news/07/14.gif?wxfrom=5&amp;wx_lazy=1"
                            _width="100%"
                            data-order="0"
                            alt="图片"
                            data-fail="0"
                          />
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                border-width: 1px;
                border-style: solid;
                border-color: rgb(251, 213, 181);
                padding: 14px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  width: 100%;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  height: 0px;
                  overflow: hidden;
                "
                data-width="100%"
              >
                <br />
              </section>
              <section
                data-autoskip="1"
                style="
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #333333;
                  background: transparent;
                  padding: 6px 0px 0px;
                  box-sizing: border-box;
                  border-width: 0px;
                  border-color: #fbd5b5;
                "
              >
                <p
                  data-v-b9592fd2=""
                  style="
                    max-inline-size: 100%;
                    margin: 0px 0px 10px;
                    padding: 0px;
                    box-sizing: border-box;
                    clear: both;
                    min-height: 1em;
                    cursor: text;
                    caret-color: #ff0000;
                    text-decoration-thickness: initial;
                    color: #142d70;
                    font-size: 16px;
                    text-indent: 32px;
                    background-color: #ffffff;
                    border-width: 0px;
                    border-color: #fbd5b5;
                    overflow-wrap: break-word !important;
                    outline: none 0px !important;
                    font-family: 微软雅黑;
                  "
                >
                  <span
                    style="
                      max-inline-size: 100%;
                      margin: 0px;
                      padding: 0px;
                      cursor: text;
                      background-color: transparent;
                      caret-color: red;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      line-height: 1.5em;
                      color: #000000;
                      border-width: 0px;
                      border-color: #fbd5b5;
                      box-sizing: border-box !important;
                      overflow-wrap: break-word !important;
                      outline: none 0px !important;
                    "
                    >中码科技是一家全国领先的权威数字身份核验服务运营商，专注于为各行业提供权威、可信、安全、便捷的合规身份认证及应用服务。</span
                  >
                </p>
                <p
                  data-v-b9592fd2=""
                  style="
                    max-inline-size: 100%;
                    margin: 0px 0px 10px;
                    padding: 0px;
                    box-sizing: border-box;
                    clear: both;
                    min-height: 1em;
                    cursor: text;
                    caret-color: #ff0000;
                    text-decoration-thickness: initial;
                    color: #142d70;
                    font-size: 16px;
                    text-indent: 32px;
                    background-color: #ffffff;
                    border-width: 0px;
                    border-color: #fbd5b5;
                    overflow-wrap: break-word !important;
                    outline: none 0px !important;
                    font-family: 微软雅黑;
                  "
                >
                  <span
                    style="
                      max-inline-size: 100%;
                      margin: 0px;
                      padding: 0px;
                      cursor: text;
                      background-color: transparent;
                      caret-color: red;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      line-height: 1.5em;
                      color: #000000;
                      border-width: 0px;
                      border-color: #fbd5b5;
                      box-sizing: border-box !important;
                      overflow-wrap: break-word !important;
                      outline: none 0px !important;
                    "
                    >中码团队依托可信身份认证、电子取证、大数据和人工智能技术，凭借创新的实我风险核验系统、智能硬件及API服务产品体系，运用便捷的集成方式及精准的智能算法，专注于为党政机关、金融机构、互联网企业、活动展会、医院、学校、园区、社区等各行业提供“人防+技防+物防”的综合性解决方案，真正做到人、证、码三者合一。</span
                  >
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </div>
</template>
