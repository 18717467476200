<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.6747720364741642"
            data-src="@/assets/images/news/01/640.png"
            data-w="658"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              visibility: visible !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
            "
            data-index="1"
            src="@/assets/images/news/01/640.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="131862" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            background-color: rgb(251, 251, 255);
            padding: 13px;
            box-sizing: border-box;
            visibility: visible;
          "
        >
          <section
            style="display: flex; justify-content: flex-start; visibility: visible"
          >
            <section
              style="
                width: 25%;
                border-top: 1px solid rgb(14, 115, 220);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 25% !important;
                visibility: visible;
              "
              data-width="25%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              text-align: justify;
              line-height: 1.75em;
              letter-spacing: 1.5px;
              font-size: 14px;
              color: rgb(51, 51, 51);
              background-color: transparent;
              padding: 13px 0px 0px;
              box-sizing: border-box;
              visibility: visible;
            "
          >
            <section
              style="background-color: transparent; visibility: visible"
              data-autoskip="1"
            >
              <p
                style="
                  text-align: center;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: rgb(0, 0, 0);
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  font-family: 宋体;
                  visibility: visible;
                "
              >
                <span style="color: rgb(79, 129, 189); visibility: visible"
                  ><strong style="visibility: visible"
                    ><span
                      style="
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(53, 50, 59);
                        font-size: 18px;
                        font-family: arial;
                        display: inline !important;
                        visibility: visible;
                      "
                      >第二十六届成都国际汽车展览会</span
                    ></strong
                  ></span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.57em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >在省、市有关部门的大力支持和指导下，作为中西部车市“风向标”的成都国际车展，共吸引129个国内外著名汽车品牌参展，展出车辆1600余辆，展会规模超20万平方米。展会首日举办了66场精彩纷呈的发布会，国内外3412家媒体9968名记者赴车展现场报道展会盛况。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.57em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >据不完全统计，整个展期“成都国际车展”的话题</span
                ><span style="color: #c00000"
                  ><strong
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 1.57em;
                        font-style: normal;
                        text-decoration: none solid #35323b;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >关注度超22亿次，观展人次达90.2万</span
                    ></strong
                  ></span
                ><strong
                  ><span
                    style="
                      font-size: 14px;
                      letter-spacing: 1.5px;
                      line-height: 1.57em;
                      font-style: normal;
                      color: #35323b;
                      text-decoration: none solid #35323b;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >，</span
                  ></strong
                ><span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.5em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >共产生订单35028台、成交金额60.87亿元。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.5em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >此次车展规模大、规格高、参展人员多、流动性大，在本届展会筹备及开展过程中，中码科技公司为组委会提供了多算法融合人像识别服务。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.5em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >多算法融合人像识别服务是指通过将多个不同的人像识别算法进行综合和融合，提高人像识别的准确性和稳定性。这种方法可以克服单一算法的局限性，能够更好地应对不同场景、光照条件和角度等因素的影响。融合多个算法也可以减少误判和漏判的情况，提高人像识别的整体性能。</span
                ><span
                  style="
                    text-indent: 2em;
                    background-color: transparent;
                    caret-color: red;
                    font-size: 14px;
                    line-height: 1.5em;
                    color: #35323b;
                    text-decoration-style: solid;
                    text-decoration-color: #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >可以提供更可靠、精确的人像识别能力，应用于展馆入口处，</span
                ><span
                  style="
                    text-indent: 2em;
                    background-color: transparent;
                    caret-color: red;
                    font-size: 14px;
                    line-height: 1.5em;
                    color: #35323b;
                    text-decoration-style: solid;
                    text-decoration-color: #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >实现快速、准确、无接触的验证和登记，</span
                ><span
                  style="
                    text-indent: 2em;
                    background-color: transparent;
                    caret-color: red;
                    font-size: 14px;
                    line-height: 1.5em;
                    color: #35323b;
                    text-decoration-style: solid;
                    text-decoration-color: #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >提供更好的用户体验和安全性保障。</span
                >
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.6343692870201096"
            data-src="@/assets/images/news/01/641.png"
            data-w="547"
            data-width="100%"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            width="100%"
            data-index="2"
            src="@/assets/images/news/01/641.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="paragraph">
        <section data-tools="135编辑器" data-id="131857">
          <section style="text-align: center; margin: 10px auto">
            <section style="background-color: #f5f8ff">
              <section
                style="display: flex; justify-content: flex-end; align-items: flex-end"
              >
                <section
                  style="
                    width: 0px;
                    height: 1px;
                    border-bottom: 22px solid #0e73dc;
                    border-left: 22px solid transparent;
                    overflow: hidden;
                    box-sizing: border-box;
                  "
                ></section>
              </section>
            </section>
          </section>
        </section>
        <section data-role="title" data-tools="135编辑器" data-id="130151">
          <section style="margin: 10px auto; display: flex; justify-content: flex-start">
            <section
              style="
                display: flex;
                border-radius: 25px;
                background-color: #1960cc;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  flex-shrink: 0;
                  background-color: #c7e8fb;
                  border-radius: 25px 0 0 25px;
                  display: flex;
                  box-sizing: border-box;
                "
              >
                <section
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9;
                  "
                >
                  <section
                    style="
                      font-size: 16px;
                      letter-spacing: 1.5px;
                      color: #1960cc;
                      transform: skew(-5deg) translateX(15px);
                      -webkit-transform: skew(-5deg) translateX(15px);
                      -moz-transform: skew(-5deg) translateX(15px);
                      -o-transform: skew(-5deg) translateX(15px);
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong>0</strong
                      ><strong data-original-title="" title="">1</strong></span
                    >
                  </section>
                </section>
                <section
                  style="
                    flex-shrink: 0;
                    margin: 0 0 0 -10px;
                    transform: translateX(10px);
                    -webkit-transform: translateX(10px);
                    -moz-transform: translateX(10px);
                    -o-transform: translateX(10px);
                  "
                >
                  <section
                    style="
                      width: 20px;
                      height: 100%;
                      background-color: rgb(198, 232, 251);
                      border-right: 3px solid rgb(255, 255, 255);
                      box-sizing: border-box;
                      transform: skew(-30deg);
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
              <section
                style="
                  font-size: 16px;
                  color: #ffffff;
                  text-align: center;
                  padding: 4px 15px 4px 25px;
                  box-sizing: border-box;
                "
              >
                <span style="font-size: 14px"
                  ><strong data-brushtype="text">门禁管理</strong></span
                >
              </section>
            </section>
          </section>
        </section>
        <section data-role="paragraph">
          <section data-role="list">
            <p
              style="
                border-width: 0px;
                border-style: solid;
                border-color: initial;
                box-sizing: border-box;
              "
            >
              <span
                style="
                  color: #35323b;
                  font-size: 14px;
                  letter-spacing: 1.5px;
                  text-indent: 2em;
                  caret-color: red;
                "
                >&nbsp; &nbsp; &nbsp;
                门禁管理：通过人像识别技术，可以快速准确地辨识参展人员和工作人员，实现自动化的门禁管理。这可以提高安全性并节约人力成本。</span
              ><br />
            </p>
            <p
              style="
                border-width: 0px;
                border-style: solid;
                border-color: initial;
                box-sizing: border-box;
              "
            >
              <br />
            </p>
          </section>
        </section>
        <section data-role="title" data-tools="135编辑器" data-id="130151">
          <section style="margin: 10px auto; display: flex; justify-content: flex-start">
            <section
              style="
                display: flex;
                border-radius: 25px;
                background-color: #1960cc;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  flex-shrink: 0;
                  background-color: #c7e8fb;
                  border-radius: 25px 0 0 25px;
                  display: flex;
                  box-sizing: border-box;
                "
              >
                <section
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9;
                  "
                >
                  <section
                    style="
                      font-size: 16px;
                      letter-spacing: 1.5px;
                      color: #1960cc;
                      transform: skew(-5deg) translateX(15px);
                      -webkit-transform: skew(-5deg) translateX(15px);
                      -moz-transform: skew(-5deg) translateX(15px);
                      -o-transform: skew(-5deg) translateX(15px);
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong>0</strong
                      ><strong data-original-title="" title="">2</strong></span
                    >
                  </section>
                </section>
                <section
                  style="
                    flex-shrink: 0;
                    margin: 0 0 0 -10px;
                    transform: translateX(10px);
                    -webkit-transform: translateX(10px);
                    -moz-transform: translateX(10px);
                    -o-transform: translateX(10px);
                  "
                >
                  <section
                    style="
                      width: 20px;
                      height: 100%;
                      background-color: #c6e8fb;
                      border-right: 3px solid #ffffff;
                      overflow: hidden;
                      box-sizing: border-box;
                      transform: skew(-30deg);
                      -webkit-transform: skew(-30deg);
                      -moz-transform: skew(-30deg);
                      -o-transform: skew(-30deg);
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
              <section
                style="
                  font-size: 16px;
                  color: #ffffff;
                  text-align: center;
                  padding: 4px 15px 4px 25px;
                  box-sizing: border-box;
                "
              >
                <span style="font-size: 14px"
                  ><strong data-brushtype="text">客流统计</strong></span
                >
              </section>
            </section>
          </section>
        </section>
        <section data-role="paragraph">
          <section data-role="list">
            <p
              style="
                border-width: 0px;
                border-style: solid;
                border-color: initial;
                box-sizing: border-box;
              "
            >
              <span
                style="
                  color: #35323b;
                  font-size: 14px;
                  letter-spacing: 1.5px;
                  text-indent: 2em;
                  caret-color: red;
                "
                >&nbsp; &nbsp;
                &nbsp;客流统计：人像识别可以对进入展会的人数进行准确统计和计算，帮助展会组织者更好地了解参展人数和客流趋势，为后续的展会规划和决策提供数据支持。</span
              ><br />
            </p>
            <p
              style="
                border-width: 0px;
                border-style: solid;
                border-color: initial;
                box-sizing: border-box;
              "
            >
              <br />
            </p>
          </section>
          <section data-role="title" data-tools="135编辑器" data-id="130151">
            <section
              style="margin: 10px auto; display: flex; justify-content: flex-start"
            >
              <section
                style="
                  display: flex;
                  border-radius: 25px;
                  background-color: #1960cc;
                  box-sizing: border-box;
                "
              >
                <section
                  style="
                    flex-shrink: 0;
                    background-color: #c7e8fb;
                    border-radius: 25px 0 0 25px;
                    display: flex;
                    box-sizing: border-box;
                  "
                >
                  <section
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      z-index: 9;
                    "
                  >
                    <section
                      style="
                        font-size: 16px;
                        letter-spacing: 1.5px;
                        color: #1960cc;
                        transform: skew(-5deg) translateX(15px);
                        -webkit-transform: skew(-5deg) translateX(15px);
                        -moz-transform: skew(-5deg) translateX(15px);
                        -o-transform: skew(-5deg) translateX(15px);
                      "
                    >
                      <span style="font-size: 14px"
                        ><strong>0</strong
                        ><strong data-original-title="" title="">3</strong></span
                      >
                    </section>
                  </section>
                  <section
                    style="
                      flex-shrink: 0;
                      margin: 0 0 0 -10px;
                      transform: translateX(10px);
                      -webkit-transform: translateX(10px);
                      -moz-transform: translateX(10px);
                      -o-transform: translateX(10px);
                    "
                  >
                    <section
                      style="
                        width: 20px;
                        height: 100%;
                        background-color: #c6e8fb;
                        border-right: 3px solid #ffffff;
                        overflow: hidden;
                        box-sizing: border-box;
                        transform: skew(-30deg);
                        -webkit-transform: skew(-30deg);
                        -moz-transform: skew(-30deg);
                        -o-transform: skew(-30deg);
                      "
                    >
                      <br />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    font-size: 16px;
                    color: #ffffff;
                    text-align: center;
                    padding: 4px 15px 4px 25px;
                    box-sizing: border-box;
                  "
                >
                  <span style="font-size: 14px"
                    ><strong data-brushtype="text">安全监控</strong></span
                  >
                </section>
              </section>
            </section>
          </section>
          <section data-role="paragraph">
            <section data-role="list">
              <p
                style="
                  border-width: 0px;
                  border-style: solid;
                  border-color: initial;
                  box-sizing: border-box;
                "
              >
                <span
                  style="
                    color: #35323b;
                    font-size: 14px;
                    letter-spacing: 1.5px;
                    text-indent: 2em;
                    caret-color: red;
                  "
                  >&nbsp; &nbsp;
                  &nbsp;安全监控：人像识别可以识别和监控展会场地内的人员活动，及时发现异常情况和可疑人员，提高展会的安全性和保护参展者的利益。</span
                ><br />
              </p>
              <p
                style="
                  border-width: 0px;
                  border-style: solid;
                  border-color: initial;
                  box-sizing: border-box;
                "
              >
                <br />
              </p>
            </section>
            <section data-role="title" data-tools="135编辑器" data-id="130151">
              <section
                style="margin: 10px auto; display: flex; justify-content: flex-start"
              >
                <section
                  style="
                    display: flex;
                    border-radius: 25px;
                    background-color: #1960cc;
                    box-sizing: border-box;
                  "
                >
                  <section
                    style="
                      flex-shrink: 0;
                      background-color: #c7e8fb;
                      border-radius: 25px 0 0 25px;
                      display: flex;
                      box-sizing: border-box;
                    "
                  >
                    <section
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 9;
                      "
                    >
                      <section
                        style="
                          font-size: 16px;
                          letter-spacing: 1.5px;
                          color: #1960cc;
                          transform: skew(-5deg) translateX(15px);
                          -webkit-transform: skew(-5deg) translateX(15px);
                          -moz-transform: skew(-5deg) translateX(15px);
                          -o-transform: skew(-5deg) translateX(15px);
                        "
                      >
                        <span style="font-size: 14px"
                          ><strong>0</strong
                          ><strong data-original-title="" title="">4</strong></span
                        >
                      </section>
                    </section>
                    <section
                      style="
                        flex-shrink: 0;
                        margin: 0 0 0 -10px;
                        transform: translateX(10px);
                        -webkit-transform: translateX(10px);
                        -moz-transform: translateX(10px);
                        -o-transform: translateX(10px);
                      "
                    >
                      <section
                        style="
                          width: 20px;
                          height: 100%;
                          background-color: #c6e8fb;
                          border-right: 3px solid #ffffff;
                          overflow: hidden;
                          box-sizing: border-box;
                          transform: skew(-30deg);
                          -webkit-transform: skew(-30deg);
                          -moz-transform: skew(-30deg);
                          -o-transform: skew(-30deg);
                        "
                      >
                        <br />
                      </section>
                    </section>
                  </section>
                  <section
                    style="
                      font-size: 16px;
                      color: #ffffff;
                      text-align: center;
                      padding: 4px 15px 4px 25px;
                      box-sizing: border-box;
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text">数据分析</strong></span
                    >
                  </section>
                </section>
              </section>
            </section>
            <section data-role="paragraph">
              <section data-role="list">
                <p
                  style="
                    border-width: 0px;
                    border-style: solid;
                    border-color: initial;
                    box-sizing: border-box;
                  "
                >
                  <span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.5em;
                      font-style: normal;
                      color: #35323b;
                      text-decoration: none solid #35323b;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >&nbsp; &nbsp;
                    &nbsp;数据分析：通过人像识别技术，可以收集参展人员的人口统计信息，如年龄、性别等，帮助展会组织者分析参展人群的特征和趋势，优化展会的资源配置和目标市场定位。</span
                  >
                </p>
                <p
                  style="
                    border-width: 0px;
                    border-style: solid;
                    border-color: initial;
                    box-sizing: border-box;
                  "
                >
                  <br />
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="height: 0px; overflow: hidden">
        <br />
      </section>
      <section data-tools="135编辑器" data-id="119902">
        <section style="margin: 10px auto">
          <section
            style="
              display: flex;
              justify-content: flex-end;
              margin: 0 0 -8px 0;
              padding: 0 40px 0 0;
              box-sizing: border-box;
            "
          >
            <section
              style="background-color: #ffffff; padding: 0 15px; box-sizing: border-box"
            >
              <section
                style="
                  width: 6px;
                  height: 15px;
                  border-left: 1px solid rgb(65, 151, 212);
                  border-right: 1px solid rgb(65, 151, 212);
                  box-sizing: border-box;
                  transform: skew(30deg);
                  overflow: hidden;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              border-width: 1px;
              border-style: solid;
              border-color: rgb(43, 139, 207);
              padding: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #445b6a;
                background: transparent;
              "
            >
              <p style="text-indent: 2em">
                <span
                  style="
                    color: #24292f;
                    font-size: 14px;
                    text-decoration-thickness: initial;
                    display: inline !important;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-family: ali-55;
                  "
                  >多算法融合人像识别可以应用于安防监控、智能门禁、车辆识别、社交媒体分析等多个领域。它提高了识别准确性和速度，为社会提供了更加安全、便捷和智能的解决方案。<span
                    style="
                      color: #24292f;
                      font-size: 14px;
                      text-decoration-thickness: initial;
                      display: inline !important;
                      font-family: ali-55;
                    "
                    >需要注意的是，在使用人像识别技术时，要遵循相关法律法规和隐私保护原则，保护个人隐私和信息安全，确保合规性和公众认可度。</span
                  ></span
                ><span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                ></span>
              </p>
            </section>
          </section>
          <section>
            <section
              style="
                width: 10%;
                height: 7px;
                background-color: rgb(229, 248, 252);
                box-sizing: border-box;
                max-width: 10% !important;
                overflow: hidden;
              "
              data-width="10%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
    </section>
    
  </div>
</template>
