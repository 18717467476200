<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible">
          <img
            data-ratio="0.45092592592592595"
            data-w="1080"
            data-src="@/assets/images/news/06/01.png"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              visibility: visible !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
            "
            data-index="1"
            src="@/assets/images/news/06/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            class=""
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="131862" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            background-color: rgb(251, 251, 255);
            padding: 13px;
            box-sizing: border-box;
            visibility: visible;
          "
        >
          <section
            style="display: flex; justify-content: flex-start; visibility: visible"
          >
            <section
              style="
                width: 25%;
                border-top: 1px solid rgb(14, 115, 220);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 25% !important;
                visibility: visible;
              "
              data-width="25%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              text-align: justify;
              line-height: 1.75em;
              letter-spacing: 1.5px;
              font-size: 14px;
              color: rgb(51, 51, 51);
              background-color: transparent;
              padding: 13px 0px 0px;
              box-sizing: border-box;
              visibility: visible;
            "
          >
            <section
              style="background-color: transparent; visibility: visible"
              data-autoskip="1"
            >
              <p
                style="
                  text-align: center;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: rgb(0, 0, 0);
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  line-height: 1.5em;
                  font-family: 宋体;
                  visibility: visible;
                "
              >
                <span
                  style="
                    color: rgb(0, 112, 192);
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    line-height: 1.71em;
                    visibility: visible;
                  "
                  ><strong style="visibility: visible"
                    ><span
                      style="
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(53, 50, 59);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >文化和旅游部&nbsp;公安部<br
                        style="visibility: visible" /></span></strong></span
                ><span
                  style="
                    color: rgb(0, 112, 192);
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    line-height: 1.71em;
                    visibility: visible;
                  "
                  ><strong style="visibility: visible"
                    ><span
                      style="
                        color: rgb(0, 112, 192);
                        font-size: 14px;
                        letter-spacing: 1.5px;
                        line-height: 24.5px;
                        font-style: normal;
                        text-decoration: none solid rgb(53, 50, 59);
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        visibility: visible;
                      "
                      >关于进一步加强大型营业性演出活动规范管理</span
                    ></strong
                  ></span
                ><strong
                  style="
                    color: rgb(0, 112, 192);
                    font-size: 14px;
                    background-color: transparent;
                    caret-color: red;
                    visibility: visible;
                  "
                  ><span
                    style="
                      line-height: 24.5px;
                      text-decoration-style: solid;
                      text-decoration-color: rgb(53, 50, 59);
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      visibility: visible;
                    "
                    >促进演出市场健康有序发展的通知</span
                  ></strong
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: rgb(0, 0, 0);
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  font-family: 宋体;
                  visibility: visible;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.71em;
                    font-style: normal;
                    color: rgb(53, 50, 59);
                    text-decoration: none solid rgb(53, 50, 59);
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    visibility: visible;
                  "
                  >各省、自治区、直辖市文化和旅游厅（局）、公安厅（局）,新疆生产建设兵团文化体育广电和旅游局、公安局：</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 0px 0px 1em;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >当前，演出市场快速恢复发展，演唱会、音乐节等大型营业性演出活动大量增加，在满足人民群众精神文化需求的同时，个别演出活动中炒票倒票、非理性追星、不文明观演等问题也较为突出。为加强观众人数在5000人以上的大型营业性演出活动（以下简称“大型演出活动”）的规范管理，促进演出市场健康有序发展，现就有关事项通知如下。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >一、明确工作原则</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（一）大型演出活动必须坚持为人民服务、为社会主义服务的方向，坚持把社会效益放在首位、实现社会效益和经济效益相统一，更好满足人民群众精神文化需求。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二）按照“谁举办谁负责”的原则，演出举办单位应当严格落实安全主体责任，严格建立并落实安全管理制度，明确安全责任人，建立并完善风险评估和应急处置工作机制。演出举办单位应当依法办理演出审批手续，安排演出节目内容，负责演出票务和现场管理，<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #000000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >加强消费者个人信息保护</span
                  >，做好现场观众引导，掌握舆情动态，及时报告有关情况，接受主管部门指导监督，主动承担法定责任和义务。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（三）文化和旅游行政部门、公安机关要严格落实属地责任，强化协作配合，加强大型演出活动规范管理，确保大型演出活动平稳有序举办。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >二、严格审批管理</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（四）省级文化和旅游行政部门、公安机关要加强对本行政区域内大型演出活动的统筹指导，大型演出活动审批前，应当经过省级文化和旅游行政部门、属地公安机关风险评估和综合研判。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（五）文化和旅游行政部门要督促演出举办单位在申请举办大型演出活动前，对可能出现的票务销售、现场管理、网络舆情等方面的风险开展自评，形成书面报告并在申请举办时提交。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（六）省级文化和旅游行政部门、属地公安机关开展风险评估，演出举办单位开展风险自评时，应当在传统的人、事、物、场等评估要素基础上，将网上热度、舆情反应、网民评价等网络特征纳入评估范畴，建立网上网下风险综合评估体系。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（七）省级文化和旅游行政部门、属地公安机关要对风险较高的演出活动加强行政指导，督促做好现场监管。对不符合安全条件的演出活动，要求受理审批的文化和旅游行政部门、公安机关不予批准。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >三、加强票务管理</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（八）大型演出活动实行<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >实名购票</span
                  >和<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >实名入场制度</span
                  >，每场演出每个身份证件只能购买一张门票，购票人与入场人身份信息保持一致。演出举办单位、演出票务销售平台应当<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >加强消费者个人信息保护</span
                  >，防止未经授权的访问以及个人信息泄露、篡改、丢失。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（九）演出举办单位应当建立大型演出活动退票机制，设定合理的梯次退票收费标准，保障购票人的正当退票权利。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十）进一步提高大型演出活动门票销售比例，演出举办单位面向市场公开销售的门票数量不得低于核准观众数量的85%。对其余15%的门票，应当在演出前24小时进行个人信息绑定，做到<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >“实名绑定、实名入场”。</span
                  ></span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十一）演出举办单位应当明示其授权的票务代理机构，引导消费者从合法渠道购买门票。应当履行告知义务，告知消费者购票、入场和退票规则。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十二）文化和旅游行政部门要督促演出举办单位、演出票务销售平台将大型演出活动的票务信息（包括但不限于演出项目的名称、演出时间、演出场次、门票数量、票务销售方案、票房收入等信息）实时传输至全国文化市场技术监管与服务平台，进一步<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >加强大型演出活动票务信息管理。</span
                  ></span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十三）文化和旅游行政部门、公安机关要加强对大型演出活动举办方和场地方工作票证的管理，严格控制发放范围，防止工作票证流入市场被非法买卖。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >四、加强演出现场管理</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十四）文化和旅游市场管理部门、综合执法机构要加强对大型演出活动的实地检查，现场核验演员信息及演出节目内容，严禁擅自变更演员及演出内容，严禁假唱、假演奏等违法违规行为。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十五）公安机关要<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >加强大型演出活动现场安全监管</span
                  >，指导演出举办单位强化事前安全评估，制定安全工作方案，<span
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >落实安保措施，配备安检设备</span
                  >，严格对进入现场的人员、车辆、物品进行安全检查，配备足够安保人员，落实应急处置措施。公安机关依法对安全工作落实情况实施监督检查，发现安全隐患责令改正，依法查处违法犯罪行为，处置突发事件。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十六）演出举办单位应当积极配合文化和旅游行政部门、公安机关监督管理，根据工作需要，采取必要的管控措施，提升演出现场管理水平。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >五、加大违法行为打击力度</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十七）文化和旅游行政部门要加强对演出票务销售平台的管理，依法处置未履行核验票务经营单位资质及演出批准文件义务、为倒卖门票提供服务等违规行为。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十八）公安机关要加大对倒卖演出票证的打击整治力度，全面收集网上网下倒卖炒作票证信息，及时发现加价、变相加价销售票证的线索，严打网上网下倒票和诈骗等违法犯罪活动。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（十九）公安机关要依法打击流量造假、造谣传谣、侵犯隐私，以及聚众闹事、滋事斗殴等网上网下各类违法犯罪活动。适时公布典型案例，及时警示震慑，以正视听。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二十）公安机关要加强对大型演出活动安全监管，对演出举办单位、场所管理者举办大型群众性演出活动违反安全管理规定，以及扰乱活动秩序的行为，要按照《大型群众性活动安全管理条例》《中华人民共和国治安管理处罚法》《中华人民共和国刑法》有关规定依法予以处罚。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >六、引导观众文明观演</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二十一）公安机关指导演出举办单位做好入场安检，禁止观众携带影响正常观演的物品入场，倡导观众文明观演。演出举办单位要加强现场巡查，及时制止不文明观演行为，维护观演秩序。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二十二）演员经纪公司及相关演艺经纪从业人员应当加强对演员的教育、提醒，积极引导演员时刻敬畏法律红线，严守道德底线。鼓励演员主动发声，引导观众遵守演出现场管理规定，共同维护演出秩序。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.63em;
                    font-style: normal;
                    text-decoration: none solid #35323b;
                    color: #0070c0;
                    font-size: 16px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >七、强化信用监管</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <strong
                  ><span
                    style="
                      font-size: 14px;
                      letter-spacing: 1.5px;
                      line-height: 1.64em;
                      font-style: normal;
                      text-decoration: none solid #35323b;
                      color: #c00000;
                      font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                    "
                    >（二十三）文化和旅游行政部门要依照《文化和旅游市场信用管理规定》，对大型演出活动中存在面向公众销售门票数量低于核准数量的85%或者为倒卖门票、买卖演出工作票证提供便利等情形的演出举办单位及票务代理机构，依法认定为文化和旅游市场失信主体。</span
                  ></strong
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >（二十四）文化和旅游行政部门、公安机关要对失信主体加大日常监管力度，对其申请营业性演出、大型群众性活动等行政许可事项予以重点关注，在行政奖励、授予称号等方面予以重点审查。</span
                >
              </p>
              <p
                style="
                  text-align: justify;
                  margin: 1em 0px 0px;
                  padding: 0px;
                  line-height: 32px;
                  margin-block: 1em;
                  margin-inline: 0px;
                  color: #000000;
                  text-decoration-thickness: initial;
                  font-size: 18px;
                  text-indent: 2em;
                  font-family: 宋体;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                  >特此通知。</span
                >
              </p>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <section data-tools="135编辑器" data-id="131857">
          <section style="text-align: center; margin: 10px auto">
            <section style="background-color: #f5f8ff">
              <section
                style="
                  padding: 30px 25px 15px 25px;
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #35323b;
                  background: transparent;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em">
                    <span
                      style="
                        font-size: 15px;
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-style: normal;
                        color: #35323b;
                        text-decoration: none solid #35323b;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      ><span
                        style="
                          font-weight: 400;
                          letter-spacing: 1.5px;
                          line-height: 1.64em;
                          font-style: normal;
                          color: #35323b;
                          text-decoration: none solid #35323b;
                          font-size: 15px;
                          font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                        "
                        >《通知》</span
                      ></span
                    ><span
                      style="
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-style: normal;
                        color: #35323b;
                        text-decoration: none solid #35323b;
                        font-size: 15px;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >从明确工作原则、严格审批管理、加强票务管理、加强演出现场管理、加大违法行为打击力度、引导观众文明观演、强化信用监管七个方面提出24条具体要求。</span
                    >
                  </p>
                  <p style="text-indent: 2em">
                    <span
                      style="
                        font-weight: 400;
                        letter-spacing: 1.5px;
                        line-height: 1.6em;
                        font-style: normal;
                        color: #35323b;
                        text-decoration: none solid #35323b;
                        font-size: 15px;
                        font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                      "
                      >《通知》要求，在票务管理方面，大型演出活动实行实名购票和实名入场制度，每场演出每个身份证件只能购买一张门票，购票人与入场人身份信息保持一致。演出举办单位、演出票务销售平台应当加强消费者个人信息保护，防止未经授权的访问以及个人信息泄露、篡改、丢失。</span
                    >
                  </p>
                </section>
              </section>
              <section
                style="display: flex; justify-content: flex-end; align-items: flex-end"
              >
                <section
                  style="
                    width: 0px;
                    height: 1px;
                    border-bottom: 22px solid #0e73dc;
                    border-left: 22px solid transparent;
                    overflow: hidden;
                    box-sizing: border-box;
                  "
                >
                  <br />
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-tools="135编辑器" data-id="131859">
          <section style="margin: 10px auto; text-align: right">
            <section style="font-size: 40px; letter-spacing: 1.5px; color: #f7f8ff">
              <strong data-brushtype="text" style="caret-color: red">NATION</strong><br />
            </section>
            <section
              style="
                background-color: #f7f8ff;
                padding: 0 25px 35px 0px;
                margin-top: -30px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  transform: translate(0px, -20px);
                  -webkit-transform: translate(0px, -20px);
                  -moz-transform: translate(0px, -20px);
                  -o-transform: translate(0px, -20px);
                "
              >
                <section
                  style="
                    width: 5px;
                    height: 34px;
                    background-color: #0e73dc;
                    margin-right: 12px;
                    overflow: hidden;
                    box-sizing: border-box;
                  "
                >
                  <br />
                </section>
                <section style="font-size: 20px; letter-spacing: 1.5px; color: #374278">
                  <strong
                    data-brushtype="text"
                    style="letter-spacing: 1.5px; font-size: 20px; line-height: 1.6em"
                    >中码科技大型展会活动管理系统</strong
                  >
                </section>
              </section>
              <section
                style="
                  padding: 0px 0 0 25px;
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #555562;
                  background: transparent;
                  box-sizing: border-box;
                "
              >
                <section data-autoskip="1">
                  <p style="text-indent: 2em">
                    <span
                      style="
                        background-color: transparent;
                        caret-color: red;
                        letter-spacing: 1.5px;
                        font-size: 14px;
                        line-height: 1.71em;
                      "
                      >针对大型活动、展会、场馆制证、实名通行、人员核验、安全管理等环节，利用人工智能、大数据、物联网、云计算等新技术，做到人员、票证、照片三者统一，实现“制证、通行实名登记、参展留痕、人像对应、分级授权管理、风险人员预警、历史记录查询、流量分析统计、报表汇总”等功能。该系统通过人防和技防相结合，简化流程，提高通行效率和会场安全，为展会打造一个更安全、更智慧、更便捷的观展体验。</span
                    >
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                  <p
                    style="
                      text-align: center;
                      margin-left: 0px;
                      margin-right: 0px;
                      line-height: 1em;
                      text-indent: 0em;
                    "
                  >
                    <img
                      class="rich_pages wxw-img"
                      data-ratio="0.5493934142114385"
                      data-src="@/assets/images/news/06/02.png"
                      data-w="577"
                      style="
                        text-indent: 2em;
                        background-color: transparent;
                        caret-color: red;
                        text-align: center;
                        vertical-align: inherit;
                        box-sizing: border-box;
                        max-width: 100% !important;
                        width: 100% !important;
                        height: auto !important;
                        visibility: visible !important;
                      "
                      data-index="2"
                      src="@/assets/images/news/06/02.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                      _width="100%"
                      crossorigin="anonymous"
                      alt="图片"
                      data-fail="0"
                    /><br />
                  </p>
                  <p style="text-indent: 2em"><br /></p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section data-tools="135编辑器" data-id="131858">
          <section style="margin: 10px auto">
            <section>
              <section style="display: flex">
                <section style="flex-shrink: 0; transform-style: preserve-3d">
                  <section
                    style="
                      color: rgb(255, 255, 255);
                      background-color: rgb(114, 199, 224);
                      padding: 3px 4px 3px 15px;
                      box-sizing: border-box;
                      transform: translateZ(10px);
                      height: 6px;
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="height: 0px; overflow: hidden">
        <br />
      </section>
      <section data-tools="135编辑器" data-id="119902">
        <section style="margin: 10px auto">
          <section
            style="
              display: flex;
              justify-content: flex-end;
              margin: 0 0 -8px 0;
              padding: 0 40px 0 0;
              box-sizing: border-box;
            "
          >
            <section
              style="background-color: #ffffff; padding: 0 15px; box-sizing: border-box"
            >
              <section
                style="
                  width: 6px;
                  height: 15px;
                  border-left: 1px solid #4197d4;
                  border-right: 1px solid #4197d4;
                  overflow: hidden;
                  box-sizing: border-box;
                  transform: skew(30deg);
                  -webkit-transform: skew(30deg);
                  -moz-transform: skew(30deg);
                  -o-transform: skew(30deg);
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              border-width: 1px;
              border-style: solid;
              border-color: rgb(43, 139, 207);
              padding: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #445b6a;
                background: transparent;
              "
            >
              <p style="text-align: center; text-indent: 0em">
                <span
                  style="
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                ></span
                ><img
                  class="rich_pages wxw-img"
                  data-ratio="0.47425742574257423"
                  data-src="@/assets/images/news/06/03.png"
                  data-w="1010"
                  data-width="442px"
                  style="
                    vertical-align: inherit;
                    box-sizing: border-box;
                    width: 442px !important;
                    height: auto !important;
                    visibility: visible !important;
                    max-width: 100%;
                  "
                  width="442"
                  data-index="3"
                  src="@/assets/images/news/06/03.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  _width="442px"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                /><span
                  style="
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                ></span>
              </p>
            </section>
          </section>
          <section>
            <section
              style="
                width: 10%;
                height: 7px;
                background-color: #e5f8fc;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 10% !important;
              "
              data-width="10%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="119902">
        <section style="margin: 10px auto">
          <section
            style="
              display: flex;
              justify-content: flex-end;
              margin: 0 0 -8px 0;
              padding: 0 40px 0 0;
              box-sizing: border-box;
            "
          >
            <section
              style="background-color: #ffffff; padding: 0 15px; box-sizing: border-box"
            >
              <section
                style="
                  width: 6px;
                  height: 15px;
                  border-left: 1px solid #4197d4;
                  border-right: 1px solid #4197d4;
                  overflow: hidden;
                  box-sizing: border-box;
                  transform: skew(30deg);
                  -webkit-transform: skew(30deg);
                  -moz-transform: skew(30deg);
                  -o-transform: skew(30deg);
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              border-width: 1px;
              border-style: solid;
              border-color: rgb(43, 139, 207);
              padding: 10px;
              box-sizing: border-box;
            "
          >
            <section
              data-autoskip="1"
              style="
                text-align: justify;
                line-height: 1.75em;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #445b6a;
                background: transparent;
              "
            >
              <p style="text-align: center; text-indent: 0em">
                <span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                ></span
                ><img
                  class="rich_pages wxw-img"
                  data-ratio="0.682648401826484"
                  data-src="@/assets/images/news/06/04.png"
                  data-w="438"
                  style="
                    vertical-align: inherit;
                    width: 438px !important;
                    height: auto !important;
                    visibility: visible !important;
                    max-width: 100%;
                  "
                  data-index="4"
                  src="@/assets/images/news/06/04.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  _width="438px"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                /><span
                  style="
                    font-weight: 400;
                    letter-spacing: 1.5px;
                    line-height: 1.64em;
                    font-style: normal;
                    color: #35323b;
                    text-decoration: none solid #35323b;
                    font-size: 15px;
                    font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
                  "
                ></span>
              </p>
              <p style="text-indent: 2em">
                <span
                  style="
                    background-color: transparent;
                    caret-color: red;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    line-height: 1.64em;
                    color: #000000;
                  "
                  >基于身份信息的唯一性，利用实我认证技术，建立门票和证件一体化管控平台，对展会内不同身份类型人员进行有效管理，确保可定位、可追溯，同时配合入场身份查验，进行人员精准化管理和信息推送。</span
                >
              </p>
            </section>
          </section>
          <section>
            <section
              style="
                width: 10%;
                height: 7px;
                background-color: #e5f8fc;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 10% !important;
              "
              data-width="10%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: center">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.7259615384615384"
            data-src="@/assets/images/news/06/05.png"
            data-w="416"
            style="
              vertical-align: inherit;
              width: 416px !important;
              height: auto !important;
              visibility: visible !important;
              max-width: 100%;
            "
            data-index="5"
            src="@/assets/images/news/06/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="416px"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
        <p style="text-indent: 2em">
          <span
            style="
              background-color: transparent;
              caret-color: red;
              letter-spacing: 1.5px;
              font-size: 14px;
              line-height: 1.5em;
            "
            >通过实我认证，确保观众信息确定，调用展会门票实名制信息中的购票信息。检票时同步上述功能核对，支持人脸识别、身份证或门票产业等一次查验即可快速入场，减少中间等步骤，避免聚集在入口处，降低风险。</span
          >
        </p>
        <p style="text-indent: 2em">
          <span
            style="
              background-color: transparent;
              caret-color: red;
              letter-spacing: 1.5px;
              font-size: 14px;
              line-height: 1.5em;
            "
            >核验设备在人证同一性验证的同时，可进行风险人员比对预警提示，并与辖区派出所进行实时联动，预防安全隐患。</span
          >
        </p>
      </section>
      <section data-role="paragraph">
        <section data-tools="135编辑器" data-id="132240">
          <section style="margin: 10px auto">
            <section style="margin: 0 0 10px; display: flex; justify-content: center">
              <section style="display: flex; align-items: flex-end">
                <section>
                  <section style="font-size: 16px; color: #7691bf; text-align: center">
                    <br />
                  </section>
                  <section
                    style="
                      width: 100%;
                      border-top: 1px solid rgb(118, 145, 191);
                      box-sizing: border-box;
                      max-width: 100% !important;
                      height: 1px;
                      overflow: hidden;
                    "
                    data-width="100%"
                  >
                    <br />
                  </section>
                  <section style="font-size: 14px; color: #fbe0d7; text-align: center">
                    <section
                      data-role="splitline"
                      data-tools="135编辑器"
                      data-id="129630"
                    >
                      <section style="margin: 10px auto">
                        <section
                          style="
                            width: 100%;
                            max-width: 100% !important;
                            box-sizing: border-box;
                          "
                          data-width="100%"
                        >
                          <img
                            data-ratio="0.09590316573556797"
                            data-w="1074"
                            data-width="100%"
                            data-src="@/assets/images/news/06/06.gif"
                            style="
                              display: block;
                              vertical-align: inherit;
                              box-sizing: border-box;
                              max-width: 100% !important;
                              width: 100% !important;
                              height: auto !important;
                              visibility: visible !important;
                            "
                            data-index="6"
                            src="@/assets/images/news/06/06.gif?wxfrom=5&amp;wx_lazy=1"
                            class="__bg_gif"
                            _width="100%"
                            data-order="0"
                            alt="图片"
                            data-fail="0"
                          />
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                border-width: 1px;
                border-style: solid;
                border-color: rgb(118, 145, 191);
                padding: 14px;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  width: 100%;
                  box-sizing: border-box;
                  max-width: 100% !important;
                  height: 0px;
                  overflow: hidden;
                "
                data-width="100%"
              >
                <br />
              </section>
              <section
                data-autoskip="1"
                style="
                  text-align: justify;
                  line-height: 1.75em;
                  letter-spacing: 1.5px;
                  font-size: 14px;
                  color: #333;
                  background: transparent;
                  padding: 6px 0 0;
                  box-sizing: border-box;
                "
              >
                <p
                  data-v-b9592fd2=""
                  style="
                    max-inline-size: 100%;
                    margin: 0px 0px 10px;
                    padding: 0px;
                    box-sizing: border-box;
                    overflow-wrap: break-word !important;
                    outline: none 0px !important;
                    clear: both;
                    min-height: 1em;
                    cursor: text;
                    caret-color: #ff0000;
                    text-decoration-thickness: initial;
                    color: #142d70;
                    font-size: 16px;
                    text-indent: 32px;
                    background-color: #ffffff;
                    font-family: 微软雅黑;
                  "
                >
                  <span
                    style="
                      max-inline-size: 100%;
                      margin: 0px;
                      padding: 0px;
                      box-sizing: border-box !important;
                      overflow-wrap: break-word !important;
                      outline: none 0px !important;
                      cursor: text;
                      background-color: transparent;
                      caret-color: red;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      line-height: 1.5em;
                      color: #000000;
                    "
                    >中码科技是一家全国领先的权威数字身份核验服务运营商，专注于为各行业提供权威、可信、安全、便捷的合规身份认证及应用服务。</span
                  >
                </p>
                <p
                  data-v-b9592fd2=""
                  style="
                    max-inline-size: 100%;
                    margin: 0px 0px 10px;
                    padding: 0px;
                    box-sizing: border-box;
                    overflow-wrap: break-word !important;
                    outline: none 0px !important;
                    clear: both;
                    min-height: 1em;
                    cursor: text;
                    caret-color: #ff0000;
                    text-decoration-thickness: initial;
                    color: #142d70;
                    font-size: 16px;
                    text-indent: 32px;
                    background-color: #ffffff;
                    font-family: 微软雅黑;
                  "
                >
                  <span
                    style="
                      max-inline-size: 100%;
                      margin: 0px;
                      padding: 0px;
                      box-sizing: border-box !important;
                      overflow-wrap: break-word !important;
                      outline: none 0px !important;
                      cursor: text;
                      background-color: transparent;
                      caret-color: red;
                      letter-spacing: 1.5px;
                      font-size: 14px;
                      line-height: 1.5em;
                      color: #000000;
                    "
                    >中码团队依托可信身份认证、电子取证、大数据和人工智能技术，凭借创新的实我风险核验系统、智能硬件及API服务产品体系，运用便捷的集成方式及精准的智能算法，专注于为党政机关、金融机构、互联网企业、活动展会、医院、学校、园区、社区等各行业提供“人防+技防+物防”的综合性解决方案，真正做到人、证、码三者合一。</span
                  >
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    
  </div>
</template>
