<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section
        data-role="paragraph"
        style="height: 0px; overflow: hidden; visibility: visible"
      >
        <br style="visibility: visible" />
      </section>
      <section
        data-role="paragraph"
        style="height: 0px; overflow: hidden; visibility: visible"
      >
        <br style="visibility: visible" />
      </section>
      <section
        data-role="title"
        data-tools="135编辑器"
        data-id="122632"
        style="visibility: visible"
      >
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: center;
            visibility: visible;
          "
        >
          <section style="display: flex; justify-content: center; visibility: visible">
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 6px;
                  height: 100%;
                  background-color: rgb(45, 118, 191);
                  border-radius: 5px;
                  box-sizing: border-box;
                  overflow: hidden;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
            <section
              style="padding: 3px 5px; box-sizing: border-box; visibility: visible"
            >
              <section
                style="
                  font-size: 16px;
                  color: rgb(45, 118, 191);
                  text-align: center;
                  letter-spacing: 1.5px;
                  background-color: rgb(242, 247, 255);
                  padding: 5px 15px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <strong
                  data-brushtype="text"
                  style="
                    letter-spacing: 1.5px;
                    font-size: 16px;
                    line-height: 1.56em;
                    visibility: visible;
                  "
                  ><p style="visibility: visible">
                    <strong
                      data-brushtype="text"
                      style="
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        line-height: 1.56em;
                        visibility: visible;
                      "
                      >2023年第八届凉山彝族火把节</strong
                    >
                  </p></strong
                >
              </section>
            </section>
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 6px;
                  height: 100%;
                  background-color: rgb(45, 118, 191);
                  border-radius: 5px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section
        data-role="title"
        data-tools="135编辑器"
        data-id="111061"
        style="visibility: visible"
      >
        <section style="visibility: visible">
          <section
            style="
              width: 130px;
              margin: auto;
              text-align: left;
              box-sizing: border-box;
              height: 0px;
              overflow: hidden;
              visibility: visible;
            "
          >
            <br style="visibility: visible" />
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <hr
          style="
            border-style: solid;
            border-width: 1px 0px 0px;
            border-color: rgba(0, 0, 0, 0.1);
            transform-origin: 0px 0px;
            transform: scale(1, 0.5);
            visibility: visible;
          "
        />
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section
        data-role="title"
        data-tools="135编辑器"
        data-id="128342"
        style="visibility: visible"
      >
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: flex-start;
            visibility: visible;
          "
        >
          <section style="display: flex; visibility: visible">
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: rgb(255, 255, 255);
                  background-color: rgb(139, 207, 255);
                  padding: 4px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <strong style="visibility: visible">0</strong
                ><strong data-original-title="" title="" style="visibility: visible"
                  >1</strong
                >
              </section>
            </section>
            <section
              style="
                border-top: 1px solid rgb(218, 239, 253);
                border-bottom: 1px solid rgb(218, 239, 253);
                display: flex;
                align-items: center;
                box-sizing: border-box;
                visibility: visible;
              "
            >
              <section
                style="
                  font-size: 16px;
                  color: rgb(62, 62, 62);
                  text-align: center;
                  padding: 0px 20px 0px 10px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <strong data-brushtype="text" style="visibility: visible"
                  ><p style="visibility: visible">
                    <span
                      style="
                        font-size: 14px;
                        letter-spacing: normal;
                        line-height: 1.5em;
                        color: rgb(0, 112, 192);
                        font-family: 微软雅黑, 'Microsoft YaHei';
                        visibility: visible;
                      "
                      >火把节｜凉山彝族传统节日</span
                    >
                  </p></strong
                >
              </section>
            </section>
            <section
              style="
                flex-shrink: 0;
                display: flex;
                align-items: flex-end;
                margin: 0px 0px 0px -15px;
                visibility: visible;
              "
            >
              <section style="width: 15px; box-sizing: border-box; visibility: visible">
                <img
                  data-width="100%"
                  data-src="@/assets/images/news/03/01.png"
                  style="
                    display: block;
                    vertical-align: inherit;
                    box-sizing: border-box;
                    visibility: visible !important;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                  "
                  data-ratio="1"
                  data-w="21"
                  data-index="1"
                  src="@/assets/images/news/03/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="text-indent: 32px; line-height: 150%; visibility: visible">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >火把节是凉山彝族众多传统节日习俗中规模最大、内容最丰富、场面最壮观、参与人数最多、最具浓郁民族特色的世代相传的盛大节日。</span
          >
        </p>
        <p style="text-indent: 32px; line-height: 150%; visibility: visible">
          <br style="visibility: visible" />
        </p>
        <p style="text-indent: 32px; line-height: 150%; visibility: visible">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >据不完全统计，8月9日、8月10日共有超过</span
          ><span style="font-size: 16px; color: rgb(0, 112, 192); visibility: visible"
            ><strong style="visibility: visible"
              ><span
                style="
                  font-size: 16px;
                  letter-spacing: normal;
                  line-height: 1.5em;
                  font-family: 微软雅黑, 'Microsoft YaHei';
                  visibility: visible;
                "
                >20万</span
              ></strong
            ></span
          ><span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >余人参与火把之夜，西昌市火把广场、春栖大道、四合乡、俄池格则村同步点燃火把，呈现出“满天星斗落人间”的火把盛况。</span
          >
        </p>
        <p style="text-indent: 32px; line-height: 150%; visibility: visible">
          <br style="visibility: visible" />
        </p>
      </section>
      <section
        data-role="paragraph"
        style="height: 0px; overflow: hidden; visibility: visible"
      >
        <br style="visibility: visible" />
      </section>
      <section
        data-role="title"
        data-tools="135编辑器"
        data-id="128342"
        style="visibility: visible"
      >
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: flex-start;
            visibility: visible;
          "
        >
          <section style="display: flex; visibility: visible">
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: rgb(255, 255, 255);
                  background-color: rgb(139, 207, 255);
                  padding: 4px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <strong style="visibility: visible">0</strong
                ><strong data-original-title="" title="" style="visibility: visible"
                  >2</strong
                >
              </section>
            </section>
            <section
              style="
                border-top: 1px solid rgb(218, 239, 253);
                border-bottom: 1px solid rgb(218, 239, 253);
                display: flex;
                align-items: center;
                box-sizing: border-box;
                visibility: visible;
              "
            >
              <section
                style="
                  font-size: 16px;
                  color: #3e3e3e;
                  text-align: center;
                  padding: 0 20px 0 10px;
                  box-sizing: border-box;
                "
              >
                <strong data-brushtype="text"
                  ><p>
                    <span
                      style="
                        font-size: 14px;
                        line-height: 1.5em;
                        color: #0070c0;
                        font-family: 微软雅黑, 'Microsoft YaHei';
                      "
                      ><span
                        style="
                          color: #0070c0;
                          font-size: 14px;
                          text-align: center;
                          caret-color: #ff0000;
                          text-decoration-thickness: initial;
                          display: inline !important;
                          font-family: 微软雅黑, 'Microsoft YaHei';
                        "
                        ><strong>人证核验机</strong></span
                      >｜预防安全隐患</span
                    >
                  </p></strong
                >
              </section>
            </section>
            <section
              style="
                flex-shrink: 0;
                display: flex;
                align-items: flex-end;
                margin: 0 0 0 -15px;
              "
            >
              <section style="width: 15px; box-sizing: border-box">
                <img
                  data-ratio="1"
                  data-w="21"
                  data-width="100%"
                  data-src="@/assets/images/news/03/01.png"
                  style="
                    display: block;
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-index="2"
                  src="@/assets/images/news/03/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 32px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >本届火把节属于大型活动，具有大型活动过程中人、财、物相对集中，<strong
              ><span
                style="
                  font-size: 14px;
                  letter-spacing: normal;
                  line-height: 1.5em;
                  color: #0070c0;
                  font-family: 微软雅黑, 'Microsoft YaHei';
                "
                >人流数量大，人员复杂、聚散频繁</span
              ></strong
            >特点。若在人员密集的大型活动中，人员风险无法提前预知，将存在巨大的安全隐患，严重影响活动各方的利益。</span
          >
        </p>
        <p style="text-align: left; text-indent: 32px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            ><span
              style="
                color: #000000;
                font-size: 14px;
                text-align: left;
                text-indent: 32px;
                caret-color: #ff0000;
                text-decoration-thickness: initial;
                display: inline !important;
                font-family: 微软雅黑, 'Microsoft YaHei';
              "
              >人证核验机自动记录持证通行人员信息，包括身份证信息、身份证照片、现场抓拍的人脸图像、比对结果以及现场截图，并实时将核验信息同步至中心，通过系统平台实时核查用户身份，真正做到预防安全隐患。</span
            ></span
          >
        </p>
      </section>
      <section data-role="paragraph">
        <p><br /></p>
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.7509225092250923"
            data-src="@/assets/images/news/03/02.png"
            data-w="542"
            style="
              margin: 0px;
              padding: 0px;
              cursor: pointer;
              color: rgb(0, 0, 0);
              text-indent: 32px;
              caret-color: red;
              background-color: rgb(255, 255, 255);
              text-decoration-thickness: initial;
              font-size: 16px;
              text-align: center;
              vertical-align: inherit;
              max-inline-size: 100%;
              box-sizing: border-box;
              font-family: 宋体;
              overflow-wrap: break-word !important;
              outline: none 0px !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="3"
            src="@/assets/images/news/03/02.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="title" data-tools="135编辑器" data-id="128342">
        <section style="margin: 10px auto; display: flex; justify-content: flex-start">
          <section style="display: flex">
            <section style="flex-shrink: 0">
              <section
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: #ffffff;
                  background-color: #8bcfff;
                  padding: 4px 4px;
                  box-sizing: border-box;
                "
              >
                <strong>0</strong><strong data-original-title="" title="">3</strong>
              </section>
            </section>
            <section
              style="
                border-top: 1px solid #daeffd;
                border-bottom: 1px solid #daeffd;
                display: flex;
                align-items: center;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  font-size: 16px;
                  color: #3e3e3e;
                  text-align: center;
                  padding: 0 20px 0 10px;
                  box-sizing: border-box;
                "
              >
                <strong data-brushtype="text"
                  ><p>
                    <span
                      style="
                        font-size: 14px;
                        line-height: 1.5em;
                        color: #0070c0;
                        font-family: 微软雅黑, 'Microsoft YaHei';
                      "
                      >无预约通道｜安全快速入场</span
                    >
                  </p></strong
                >
              </section>
            </section>
            <section
              style="
                flex-shrink: 0;
                display: flex;
                align-items: flex-end;
                margin: 0 0 0 -15px;
              "
            >
              <section style="width: 15px; box-sizing: border-box">
                <img
                  data-width="100%"
                  data-src="@/assets/images/news/03/01.png"
                  style="
                    display: block;
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-ratio="1"
                  data-w="21"
                  data-index="4"
                  src="@/assets/images/news/03/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 32px; line-height: 150%">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >为保证本届火把节有序安全入场，线上均已开通预约入场通道，许多远道而来的外地游客并未提前预约，且因安全规范问题不能随意进出场所。</span
          >
        </p>
        <p style="text-align: left; text-indent: 32px; line-height: 150%"><br /></p>
        <p style="text-align: left; text-indent: 32px; line-height: 150%">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >此时在“第一防线”入口处，中码科技Q版人证核验机已赋能无预约通道，未预约的游客只需出具身份证，现场人证核验机设备通过实时抓拍活体人脸图像，读取身份证芯片信息，<strong
              ><span
                style="
                  font-size: 14px;
                  letter-spacing: normal;
                  line-height: 1.5em;
                  color: #0070c0;
                  font-family: 微软雅黑, 'Microsoft YaHei';
                "
                >实现人证同一性安全快速进入会场。</span
              ></strong
            ></span
          >
        </p>
      </section>
      <section data-role="paragraph">
        <p><br /></p>
      </section>
      <section data-role="paragraph">
        <p style="text-align: center">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.90625"
            data-src="@/assets/images/news/03/03.png"
            data-w="416"
            data-width="500px"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              width: 500px !important;
              height: auto !important;
              visibility: visible !important;
            "
            width="500px"
            data-index="5"
            src="@/assets/images/news/03/03.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="500px"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="paragraph" style="height: 0px; overflow: hidden">
        <br />
      </section>
      <section data-role="paragraph" style="height: 0px; overflow: hidden">
        <br />
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="1"
            data-src="@/assets/images/news/03/04.gif"
            data-w="640"
            style="
              margin: 0px;
              padding: 0px;
              cursor: pointer;
              color: rgb(0, 0, 0);
              font-size: 17px;
              text-align: center;
              caret-color: rgb(255, 0, 0);
              background-color: rgb(255, 255, 255);
              text-decoration-thickness: initial;
              vertical-align: inherit;
              max-inline-size: 100%;
              box-sizing: border-box;
              font-family: 微软雅黑, 'Microsoft YaHei', sans-serif;
              overflow-wrap: break-word !important;
              outline: none 0px !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="6"
            src="@/assets/images/news/03/04.gif?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="title" data-tools="135编辑器" data-id="128342">
        <section style="margin: 10px auto; display: flex; justify-content: flex-start">
          <section style="display: flex">
            <section style="flex-shrink: 0">
              <section
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: #ffffff;
                  background-color: #8bcfff;
                  padding: 4px 4px;
                  box-sizing: border-box;
                "
              >
                <strong>0</strong><strong data-original-title="" title="">4</strong>
              </section>
            </section>
            <section
              style="
                border-top: 1px solid #daeffd;
                border-bottom: 1px solid #daeffd;
                display: flex;
                align-items: center;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  font-size: 16px;
                  color: #3e3e3e;
                  text-align: center;
                  padding: 0 20px 0 10px;
                  box-sizing: border-box;
                "
              >
                <strong
                  data-brushtype="text"
                  style="letter-spacing: normal; font-size: 14px; line-height: 1.5em"
                  ><p>
                    <span
                      style="
                        font-size: 14px;
                        line-height: 1.5em;
                        color: #0070c0;
                        font-family: 微软雅黑, 'Microsoft YaHei';
                      "
                      >存储安全｜大数据提供秒级安全保障</span
                    >
                  </p></strong
                >
              </section>
            </section>
            <section
              style="
                flex-shrink: 0;
                display: flex;
                align-items: flex-end;
                margin: 0 0 0 -15px;
              "
            >
              <section style="width: 15px; box-sizing: border-box">
                <img
                  data-width="100%"
                  data-src="@/assets/images/news/03/01.png"
                  style="
                    display: block;
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-ratio="1"
                  data-w="21"
                  data-index="7"
                  src="@/assets/images/news/03/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 32px; line-height: 150%">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >设备采集数据后 ，通过互联网、局域网、公安网进行传输
            ，数据接入汇聚并进行数据治理后归库存储
            ，数据支撑应用功能调用建立业务实操流程</span
          ><span
            style="
              caret-color: red;
              font-size: 14px;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >，为人口数据研判、预警等工作提供数据支撑</span
          ><span
            style="
              caret-color: red;
              font-size: 14px;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >。</span
          >
        </p>
        <p style="text-indent: 2em">
          <span
            style="
              font-size: 14px;
              caret-color: red;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            ><span
              style="
                color: #000000;
                font-size: 14px;
                text-indent: 28px;
                caret-color: #ff0000;
                text-decoration-thickness: initial;
                display: inline !important;
                font-family: 微软雅黑, 'Microsoft YaHei';
              "
              >数据存储在专网内 ，互联网无敏感数据；</span
            >群众身份信息本地不储存 ，有效避免信息泄露风险。</span
          >
        </p>
      </section>
      <section data-role="paragraph">
        <p><br /></p>
      </section>
      <section data-role="paragraph">
        <p style="text-align: center">
          <img
            class="rich_pages wxw-img"
            data-ratio="1.4862745098039216"
            data-src="@/assets/images/news/03/05.png"
            data-w="510"
            data-width="463px"
            style="
              margin: 0px;
              padding: 0px;
              color: rgb(0, 0, 0);
              caret-color: red;
              text-decoration-thickness: initial;
              cursor: pointer;
              text-indent: 32px;
              background-color: rgb(255, 255, 255);
              font-size: 14px;
              text-align: center;
              vertical-align: inherit;
              max-inline-size: 100%;
              box-sizing: border-box;
              font-family: 微软雅黑, 'Microsoft YaHei';
              overflow-wrap: break-word !important;
              outline: none 0px !important;
              width: 463px !important;
              height: auto !important;
              visibility: visible !important;
            "
            width="463"
            data-index="8"
            src="@/assets/images/news/03/05.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="463px"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="paragraph">
        <p><br /></p>
      </section>
      <section data-role="title" data-tools="135编辑器" data-id="128342">
        <section style="margin: 10px auto; display: flex; justify-content: flex-start">
          <section style="display: flex">
            <section style="flex-shrink: 0">
              <section
                style="
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  color: #ffffff;
                  background-color: #8bcfff;
                  padding: 4px 4px;
                  box-sizing: border-box;
                "
              >
                <strong>0</strong><strong data-original-title="" title="">5</strong>
              </section>
            </section>
            <section
              style="
                border-top: 1px solid #daeffd;
                border-bottom: 1px solid #daeffd;
                display: flex;
                align-items: center;
                box-sizing: border-box;
              "
            >
              <section
                style="
                  font-size: 16px;
                  color: #3e3e3e;
                  text-align: center;
                  padding: 0 20px 0 10px;
                  box-sizing: border-box;
                "
              >
                <strong data-brushtype="text" style="font-size: 14px; line-height: 1.5em"
                  ><p>
                    <span
                      style="
                        font-size: 14px;
                        line-height: 1.5em;
                        color: #0070c0;
                        font-family: 微软雅黑, 'Microsoft YaHei';
                      "
                      >紧密合作｜开幕式圆满成功</span
                    >
                  </p></strong
                >
              </section>
            </section>
            <section
              style="
                flex-shrink: 0;
                display: flex;
                align-items: flex-end;
                margin: 0 0 0 -15px;
              "
            >
              <section style="width: 15px; box-sizing: border-box">
                <img
                  data-ratio="1"
                  data-w="21"
                  data-width="100%"
                  data-src="@/assets/images/news/03/01.png"
                  style="
                    display: block;
                    vertical-align: inherit;
                    box-sizing: border-box;
                    max-width: 100% !important;
                    width: 100% !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-index="9"
                  src="@/assets/images/news/03/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" draggable="true">
        <p style="text-align: left; text-indent: 32px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >8月10日晚，2023年第八届凉山彝族火把节暨夏季清凉凉山游系列活动在西昌开幕，我司配合当地单位对各项安保出入管理工作进行反复推演和周密部署，实地踏勘各保障要素的操作性、执行性和应变能力，坚决确保各关键点位“不出事、不生乱”，以点带面推进安保任务取得圆满成功。</span
          >
        </p>
      </section>
    </section>
    
  </div>
</template>
