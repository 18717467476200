<template>
  <div
    class="rich_media_content js_underline_content autoTypeSetting24psection"
    id="js_content"
    style=""
  >
    <section
      data-role="outer"
      label="edit by 135editor"
      style="visibility: visible"
    >
      <section
        data-role="outer"
        label="edit by 135editor"
        data-tplid="135283"
        style="visibility: visible"
      >
        <section
          style="margin-right: 10px; margin-left: 10px; visibility: visible"
        >
          <section
            data-tools="135编辑器"
            data-id="119991"
            style="visibility: visible"
          >
            <section
              style="margin: 10px auto; text-align: center; visibility: visible"
            >
              <section style="display: inline-block; visibility: visible">
                <section
                  style="
                    border-bottom: 2px solid rgb(3, 63, 206);
                    padding-bottom: 4px;
                    visibility: visible;
                  "
                >
                  <section style="visibility: visible">
                    <section
                      style="
                        font-size: 22px;
                        letter-spacing: 1.5px;
                        color: rgb(3, 63, 206);
                        visibility: visible;
                      "
                    >
                      <strong data-brushtype="text" style="visibility: visible"
                        ><p style="text-align: center; visibility: visible">
                          <span
                            style="
                              letter-spacing: 0px;
                              background: rgb(255, 255, 255);
                              font-size: 21px;
                              visibility: visible;
                            "
                            >以智慧科技助力“汤尤杯”赛事辉煌</span
                          >
                        </p></strong
                      >
                    </section>
                    <section
                      style="
                        display: flex;
                        justify-content: center;
                        margin-top: 2px;
                        visibility: visible;
                      "
                    >
                      <section
                        style="
                          height: 2px;
                          background: rgb(3, 63, 206);
                          align-self: flex-end;
                          width: 50%;
                          overflow: hidden;
                          max-width: 50% !important;
                          visibility: visible;
                        "
                        data-width="50%"
                      >
                        <br style="visibility: visible" />
                      </section>
                    </section>
                  </section>
                </section>
                <section
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      width: 15px;
                      height: 0px;
                      overflow: hidden;
                      visibility: visible;
                    "
                  >
                    <br style="visibility: visible" />
                  </section>
                  <section
                    style="
                      font-size: 18px;
                      letter-spacing: 1.5px;
                      padding: 4px 6px;
                      color: rgb(255, 222, 91);
                      visibility: visible;
                    "
                  >
                    <strong data-brushtype="text" style="visibility: visible"
                      >中码科技</strong
                    >
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section
            data-tools="135编辑器"
            data-id="134548"
            style="visibility: visible"
          >
            <section
              style="
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                visibility: visible;
              "
            >
              <section style="display: flex; visibility: visible">
                <section style="visibility: visible">
                  <section
                    style="
                      font-size: 16px;
                      color: rgb(3, 63, 206);
                      border-width: 1px;
                      border-style: solid;
                      border-color: rgb(3, 63, 206);
                      padding: 2px 20px;
                      margin-left: 2px;
                      visibility: visible;
                    "
                  >
                    <strong style="visibility: visible"
                      ><span
                        data-brushtype="text"
                        style="visibility: visible"
                      ></span
                    ></strong>
                    <p style="visibility: visible">
                      <strong style="visibility: visible"
                        ><span
                          style="
                            letter-spacing: 0px;
                            background: rgb(255, 255, 255);
                            visibility: visible;
                          "
                          >中码科技智慧助力，汤尤杯圆满落幕</span
                        ></strong
                      >
                    </p>
                    <section
                      style="
                        height: 4px;
                        width: 100%;
                        background-color: rgb(255, 222, 91);
                        overflow: hidden;
                        visibility: visible;
                      "
                      data-width="100%"
                    >
                      <br style="visibility: visible" />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    flex-shrink: 0;
                    display: flex;
                    align-items: flex-end;
                    padding-top: 3px;
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      width: 4px;
                      height: 100%;
                      background-color: rgb(255, 222, 91);
                      overflow: hidden;
                      visibility: visible;
                    "
                  >
                    <br style="visibility: visible" />
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="visibility: visible">
            <section
              data-tools="135编辑器"
              data-id="134530"
              style="visibility: visible"
            >
              <section
                style="
                  margin: 10px auto;
                  background-position: center top;
                  background-repeat: no-repeat;
                  background-size: cover;
                  padding: 3px;
                  visibility: visible;
                "
                :style="{
                  background:
                    'url(' +
                    require('@/assets/images/news/08/02.gif') +
                    ') center center / 100% no-repeat',
                  backgroundSize: '100% 100%',
                }"
                data-lazy-bgimg="@/assets/images/news/08/02.gif"
                class=""
                data-fail="0"
              >
                <section
                  style="width: 100%; visibility: visible"
                  data-width="100%"
                >
                  <img
                    class="rich_pages wxw-img"
                    data-imgfileid="100000414"
                    data-ratio="0.7493606138107417"
                    data-src="@/assets/images/news/08/02.png"
                    data-type="png"
                    data-w="391"
                    data-width="404px"
                    style="
                      vertical-align: initial;
                      width: 100% !important;
                      height: auto !important;
                      visibility: visible !important;
                    "
                    width="404"
                    data-original-style="vertical-align: initial;width: 404px;"
                    data-index="2"
                    src="@/assets/images/news/08/02.png"
                    _width="404px"
                    crossorigin="anonymous"
                    alt="图片"
                    data-fail="0"
                  />
                </section>
              </section>
            </section>
            <section style="visibility: visible">
              <section
                style="
                  line-height: 30.75px;
                  color: rgb(71, 71, 71);
                  text-shadow: none;
                  visibility: visible;
                "
                data-autoskip="1"
              >
                <p style="text-indent: 2em; visibility: visible">
                  <span
                    style="
                      font-size: 14px;
                      letter-spacing: 0px;
                      background: rgb(255, 255, 255);
                      visibility: visible;
                    "
                    >在成都高新体育中心举办的2024年汤尤杯羽毛球赛圆满落幕。九天的激烈角逐，为观众带来了一场场视觉盛宴。在这背后，中码科技以其卓越的技术实力和细致入微的服务，为赛事的成功举办提供了强有力的支撑。</span
                  >
                </p>
                <p style="font-size: 14px; visibility: visible">
                  <br style="visibility: visible" />
                </p>
              </section>
            </section>
          </section>
          <section
            data-tools="135编辑器"
            data-id="134548"
            style="visibility: visible"
          >
            <section
              style="
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                visibility: visible;
              "
            >
              <section style="display: flex; visibility: visible">
                <section style="visibility: visible">
                  <section
                    style="
                      font-size: 16px;
                      color: rgb(3, 63, 206);
                      border-width: 1px;
                      border-style: solid;
                      border-color: rgb(3, 63, 206);
                      padding: 2px 20px;
                      margin-left: 2px;
                      visibility: visible;
                    "
                  >
                    <strong style="visibility: visible"
                      ><span data-brushtype="text" style="visibility: visible"
                        ><p style="visibility: visible">
                          <strong style="visibility: visible"
                            ><span
                              style="
                                letter-spacing: 0px;
                                background: rgb(255, 255, 255);
                                visibility: visible;
                              "
                              ><strong style="visibility: visible"
                                >智能验证，高效安全</strong
                              ></span
                            ></strong
                          >
                        </p></span
                      ></strong
                    >
                  </section>
                  <section style="padding-left: 4px; visibility: visible">
                    <section
                      style="
                        height: 4px;
                        width: 100%;
                        background-color: rgb(255, 222, 91);
                        overflow: hidden;
                        visibility: visible;
                      "
                      data-width="100%"
                    >
                      <br style="visibility: visible" />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    flex-shrink: 0;
                    display: flex;
                    align-items: flex-end;
                    padding-top: 3px;
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      width: 4px;
                      height: 100%;
                      background-color: rgb(255, 222, 91);
                      overflow: hidden;
                      visibility: visible;
                    "
                  >
                    <br style="visibility: visible" />
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section style="visibility: visible">
            <section style="visibility: visible">
              <section
                style="
                  line-height: 30.75px;
                  color: rgb(71, 71, 71);
                  font-size: 14px;
                  text-shadow: none;
                  visibility: visible;
                "
                data-autoskip="1"
              >
                <section data-id="undefined" style="visibility: visible">
                  <section
                    data-tools="135编辑器"
                    data-id="134530"
                    style="visibility: visible"
                  >
                    <section
                      style="
                        margin: 10px auto;
                        background-position: center top;
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 3px;
                        visibility: visible;
                      "
                      :style="{
                        background:
                          'url(' +
                          require('@/assets/images/news/08/02.gif') +
                          ') center center / 100% no-repeat',
                        backgroundSize: '100% 100%',
                      }"
                      data-lazy-bgimg="@/assets/images/news/08/02.gif"
                      class=""
                      data-fail="0"
                    >
                      <section
                        style="width: 100%; visibility: visible"
                        data-width="100%"
                      >
                        <img
                          class="rich_pages wxw-img"
                          data-imgfileid="100000418"
                          data-ratio="0.5453703703703704"
                          data-src="@/assets/images/news/08/03.png"
                          data-type="png"
                          data-w="1080"
                          data-width="100%"
                          style="
                            width: 100% !important;
                            display: block;
                            vertical-align: inherit;
                            height: auto !important;
                            visibility: visible !important;
                          "
                          data-original-style="width: 100%;display: block;vertical-align: inherit;"
                          data-index="3"
                          src="@/assets/images/news/08/03.png"
                          _width="100%"
                          crossorigin="anonymous"
                          alt="图片"
                          data-fail="0"
                        />
                      </section>
                    </section>
                  </section>
                </section>
                <section data-tools="135编辑器" data-id="142418">
                  <section style="margin: 10px auto">
                    <section
                      data-op="division"
                      style="display: flex; justify-content: space-between"
                    >
                      <section
                        style="
                          width: 49%;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          max-width: 49% !important;
                        "
                        data-width="49%"
                      >
                        <section style="width: 100%" data-width="100%">
                          <img
                            class="rich_pages wxw-img"
                            data-imgfileid="100000415"
                            data-ratio="0.7481481481481481"
                            data-src="@/assets/images/news/08/04.webp"
                            data-type="jpeg"
                            data-w="1080"
                            data-width="100%"
                            style="
                              width: 100% !important;
                              display: block;
                              vertical-align: inherit;
                              height: auto !important;
                              visibility: visible !important;
                            "
                            data-original-style="width: 100%;display: block;vertical-align: inherit;"
                            data-index="4"
                            src="@/assets/images/news/08/04.webp"
                            _width="100%"
                            crossorigin="anonymous"
                            alt="图片"
                            data-fail="0"
                          />
                        </section>
                        <section
                          style="display: flex; justify-content: flex-start"
                        >
                          <section>
                            <section
                              style="
                                width: 30px;
                                margin-bottom: -7px;
                                transform: rotateY(180deg);
                                height: 0px;
                                overflow: hidden;
                              "
                            >
                              <br />
                            </section>
                            <section
                              style="
                                width: 70%;
                                height: 10px;
                                background-color: rgb(229, 248, 255);
                                margin-top: -8px;
                                overflow: hidden;
                                max-width: 70% !important;
                              "
                              data-width="70%"
                            >
                              <br />
                            </section>
                          </section>
                        </section>
                      </section>
                      <section
                        style="width: 49%; max-width: 49% !important"
                        data-width="49%"
                      >
                        <img
                          class="rich_pages wxw-img"
                          data-imgfileid="100000417"
                          data-ratio="0.7416666666666667"
                          data-src="@/assets/images/news/08/05.webp"
                          data-type="png"
                          data-w="1080"
                          style="
                            vertical-align: initial;
                            height: auto !important;
                            visibility: visible !important;
                            width: 100% !important;
                          "
                          data-original-style="vertical-align:initial;"
                          data-index="5"
                          src="@/assets/images/news/08/05.webp"
                          _width="677px"
                          crossorigin="anonymous"
                          alt="图片"
                          data-fail="0"
                        />
                      </section>
                    </section>
                  </section>
                </section>
                <section data-id="undefined">
                  <section data-tools="135编辑器" data-id="134530">
                    <section
                      style="
                        margin: 10px auto;
                        background-position: center top;
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 3px;
                      "
                      :style="{
                        background:
                          'url(' +
                          require('@/assets/images/news/08/02.gif') +
                          ') center center / 100% no-repeat',
                        backgroundSize: '100% 100%',
                      }"
                      data-lazy-bgimg="@/assets/images/news/08/02.gif"
                      class=""
                      data-fail="0"
                    >
                      <section style="width: 100%" data-width="100%">
                        <img
                          class="rich_pages wxw-img"
                          data-imgfileid="100000416"
                          data-ratio="0.5604203152364273"
                          data-src="@/assets/images/news/08/06.png"
                          data-type="png"
                          data-w="571"
                          data-width="100%"
                          style="
                            width: 100% !important;
                            display: block;
                            vertical-align: inherit;
                            height: auto !important;
                            visibility: visible !important;
                          "
                          data-original-style="width: 100%;display: block;vertical-align: inherit;"
                          data-index="6"
                          src="@/assets/images/news/08/06.png"
                          _width="100%"
                          crossorigin="anonymous"
                          alt="图片"
                          data-fail="0"
                        />
                      </section>
                    </section>
                  </section>
                </section>
                <p style="text-indent: 32px">
                  <span
                    style="letter-spacing: 0px; background: rgb(255, 255, 255)"
                    >为了确保汤尤杯赛事的顺利进行，中码科技为赛事提供了专业的车辆验证设备以及人证验证设备。这些设备均采用了最新的人脸识别和身份验证技术，能够快速、准确地完成查验工作，从而确保了所有工作人员和嘉宾的顺利入场，为赛事的顺利进行提供了坚实的保障。</span
                  >
                </p>
                <p style="text-indent: 32px"><br /></p>
              </section>
            </section>
          </section>
          <section data-tools="135编辑器" data-id="134548">
            <section
              style="
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
              "
            >
              <section style="display: flex">
                <section>
                  <section
                    style="
                      font-size: 16px;
                      color: rgb(3, 63, 206);
                      border-width: 1px;
                      border-style: solid;
                      border-color: rgb(3, 63, 206);
                      padding: 2px 20px;
                      margin-left: 2px;
                    "
                  >
                    <strong style="caret-color: red"
                      ><span
                        style="
                          letter-spacing: 0px;
                          background-image: initial;
                          background-position: initial;
                          background-size: initial;
                          background-repeat: initial;
                          background-attachment: initial;
                          background-origin: initial;
                          background-clip: initial;
                        "
                        >科技助力，共创辉煌</span
                      ></strong
                    >
                  </section>
                  <section style="padding-left: 4px">
                    <section
                      style="
                        height: 4px;
                        width: 100%;
                        background-color: rgb(255, 222, 91);
                        overflow: hidden;
                      "
                      data-width="100%"
                    >
                      <br />
                    </section>
                  </section>
                </section>
                <section
                  style="
                    flex-shrink: 0;
                    display: flex;
                    align-items: flex-end;
                    padding-top: 3px;
                  "
                >
                  <section
                    style="
                      width: 4px;
                      height: 100%;
                      background-color: rgb(255, 222, 91);
                      overflow: hidden;
                    "
                  >
                    <br />
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section>
            <section data-tools="135编辑器" data-id="134530">
              <section
                style="
                  margin: 10px auto;
                  background-position: center top;
                  background-repeat: no-repeat;
                  background-size: cover;
                  padding: 3px;
                "
                :style="{
                  background:
                    'url(' +
                    require('@/assets/images/news/08/02.gif') +
                    ') center center / 100% no-repeat',
                  backgroundSize: '100% 100%',
                }"
                data-lazy-bgimg="@/assets/images/news/08/02.gif"
                class="wx_imgbc_placeholder"
              >
                <section style="width: 100%" data-width="100%">
                  <img
                    class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                    data-imgfileid="100000422"
                    data-ratio="0.562962962962963"
                    data-src="@/assets/images/news/08/07.webp"
                    data-type="jpeg"
                    data-w="1080"
                    style="
                      vertical-align: initial;
                      width: 100% !important;
                      height: auto !important;
                    "
                    data-original-style="vertical-align:initial;"
                    data-index="7"
                    src="@/assets/images/news/08/07.webp"
                    _width="677px"
                    crossorigin="anonymous"
                    alt="图片"
                  />
                </section>
              </section>
            </section>
            <p style="text-indent: 2em">
              <span
                style="
                  max-inline-size: 100%;
                  cursor: text;
                  color: rgb(71, 71, 71);
                  letter-spacing: normal;
                  caret-color: red;
                  font-size: 14px;
                  line-height: 1.5em;
                  font-family: mp-quote, -apple-system-font, BlinkMacSystemFont,
                    Arial, sans-serif;
                "
                >中码科技以科技为核心，持续创新服务模式与技术应用，在体育赛事的举办中发挥了关键的技术支持和服务保障作用。特别值得一提的是，中码科技引入了先进的</span
              ><span
                style="
                  color: #033fce;
                  letter-spacing: normal;
                  font-size: 14px;
                  line-height: 2.14em;
                "
                ><strong
                  ><span
                    style="letter-spacing: 0px; background: rgb(255, 255, 255)"
                    >数据孪生</span
                  ></strong
                ></span
              ><span style="color: #033fce"
                ><strong
                  style="
                    max-inline-size: 100%;
                    cursor: text;
                    color: rgb(71, 71, 71);
                    caret-color: rgb(255, 0, 0);
                    font-family: mp-quote, -apple-system-font,
                      BlinkMacSystemFont, Arial, sans-serif;
                  "
                  ><span
                    style="
                      max-inline-size: 100%;
                      cursor: text;
                      letter-spacing: 0px;
                      caret-color: red;
                      font-size: 14px;
                    "
                  ></span></strong
                ><strong
                  style="
                    max-inline-size: 100%;
                    cursor: text;
                    color: rgb(71, 71, 71);
                    caret-color: rgb(255, 0, 0);
                    font-family: mp-quote, -apple-system-font,
                      BlinkMacSystemFont, Arial, sans-serif;
                  "
                ></strong
                ><strong
                  style="
                    max-inline-size: 100%;
                    cursor: text;
                    color: rgb(71, 71, 71);
                    caret-color: rgb(255, 0, 0);
                    font-family: mp-quote, -apple-system-font,
                      BlinkMacSystemFont, Arial, sans-serif;
                  "
                  ><span
                    style="
                      max-inline-size: 100%;
                      cursor: text;
                      letter-spacing: 0px;
                      caret-color: red;
                      font-size: 14px;
                    "
                  ></span></strong></span
              ><span
                style="
                  max-inline-size: 100%;
                  cursor: text;
                  color: rgb(71, 71, 71);
                  letter-spacing: 0px;
                  caret-color: red;
                  font-size: 14px;
                  font-family: mp-quote, -apple-system-font, BlinkMacSystemFont,
                    Arial, sans-serif;
                "
                >系统，实时追踪并监控现场的人流数据，为赛事管理提供了前所未有的精准度和效率。</span
              >
            </p>
            <section>
              <section
                style="
                  line-height: 30.75px;
                  color: rgb(71, 71, 71);
                  text-shadow: none;
                "
                data-autoskip="1"
              >
                <section data-id="undefined">
                  <section data-tools="135编辑器" data-id="134530">
                    <section
                      style="
                        margin: 10px auto;
                        background-position: center top;
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 3px;
                      "
                      :style="{
                        background:
                          'url(' +
                          require('@/assets/images/news/08/02.gif') +
                          ') center center / 100% no-repeat',
                        backgroundSize: '100% 100%',
                      }"
                      data-lazy-bgimg="@/assets/images/news/08/02.gif"
                      class="wx_imgbc_placeholder"
                    >
                      <section style="width: 100%" data-width="100%">
                        <img
                          class="rich_pages wxw-img js_img_placeholder wx_img_placeholder"
                          data-imgfileid="100000421"
                          data-ratio="0.562962962962963"
                          data-src="@/assets/images/news/08/08.webp"
                          data-type="jpeg"
                          data-w="1080"
                          style="
                            vertical-align: initial;
                            width: 100% !important;
                            height: auto !important;
                          "
                          data-original-style="vertical-align:initial;"
                          data-index="8"
                          src="@/assets/images/news/08/08.webp"
                          _width="677px"
                          crossorigin="anonymous"
                          alt="图片"
                        />
                      </section>
                    </section>
                  </section>
                </section>
                <p style="font-size: 14px; text-indent: 2em">
                  <span
                    style="
                      max-inline-size: 100%;
                      cursor: text;
                      letter-spacing: normal;
                      text-indent: 34px;
                      caret-color: red;
                      line-height: 1.5em;
                      font-family: mp-quote, -apple-system-font,
                        BlinkMacSystemFont, Arial, sans-serif;
                    "
                    >数字孪生系统还内置了应急预案模块，能够实时显示应急逃生路线，为现场应急指挥提供快速、有效的方案。此外，该系统还可以提供历史总览功能，允许管理人员回顾、分析过去数据，以优化未来的赛事组织和安全策略。</span
                  >
                </p>
                <p style="font-size: 14px; text-indent: 2em">
                  <span
                    style="
                      max-inline-size: 100%;
                      cursor: text;
                      letter-spacing: normal;
                      text-indent: 34px;
                      caret-color: red;
                      line-height: 1.5em;
                      font-family: mp-quote, -apple-system-font,
                        BlinkMacSystemFont, Arial, sans-serif;
                    "
                    ><br
                  /></span>
                </p>
              </section>
            </section>
          </section>
          <section>
            <section data-id="134548">
              <section
                style="
                  margin-top: 10px;
                  margin-bottom: 20px;
                  display: flex;
                  justify-content: center;
                "
              >
                <section style="display: flex">
                  <section>
                    <section
                      style="
                        font-size: 16px;
                        color: rgb(3, 63, 206);
                        border-width: 1px;
                        border-style: solid;
                        border-color: rgb(3, 63, 206);
                        padding: 2px 20px;
                        margin-left: 2px;
                      "
                    >
                      <strong style="caret-color: red"
                        ><span
                          style="
                            letter-spacing: 0px;
                            background-image: initial;
                            background-position: initial;
                            background-size: initial;
                            background-repeat: initial;
                            background-attachment: initial;
                            background-origin: initial;
                            background-clip: initial;
                          "
                          >展望未来，砥砺前行</span
                        ></strong
                      >
                    </section>
                    <section style="padding-left: 4px">
                      <section
                        style="
                          height: 4px;
                          width: 100%;
                          background-color: rgb(255, 222, 91);
                          overflow: hidden;
                        "
                        data-width="100%"
                      >
                        <br />
                      </section>
                    </section>
                  </section>
                  <section
                    style="
                      flex-shrink: 0;
                      display: flex;
                      align-items: flex-end;
                      padding-top: 3px;
                    "
                  >
                    <section
                      style="
                        width: 4px;
                        height: 100%;
                        background-color: rgb(255, 222, 91);
                        overflow: hidden;
                      "
                    >
                      <br />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section data-id="undefined">
              <section>
                <section
                  style="
                    line-height: 30.75px;
                    color: rgb(71, 71, 71);
                    text-shadow: none;
                    font-size: 14px;
                  "
                  data-autoskip="1"
                >
                  <p style="text-indent: 32px">
                    <span
                      style="
                        letter-spacing: 0px;
                        background: rgb(255, 255, 255);
                      "
                      >展望未来，中码科技将坚守</span
                    ><span
                      style="
                        color: rgb(3, 63, 206);
                        letter-spacing: normal;
                        line-height: 2em;
                      "
                      ><strong
                        ><span
                          style="
                            letter-spacing: 0px;
                            background: rgb(255, 255, 255);
                          "
                          >“<span
                            style="
                              letter-spacing: 0px;
                              background-image: initial;
                              background-position: initial;
                              background-size: initial;
                              background-repeat: initial;
                              background-attachment: initial;
                              background-origin: initial;
                              background-clip: initial;
                            "
                            >让公共更安全</span
                          >”</span
                        ></strong
                      ></span
                    ><span
                      style="
                        letter-spacing: 0px;
                        background: rgb(255, 255, 255);
                      "
                      >的使命，以</span
                    ><span
                      style="
                        color: rgb(3, 63, 206);
                        letter-spacing: normal;
                        line-height: 2.07em;
                      "
                      ><strong
                        ><span
                          style="
                            letter-spacing: 0px;
                            background: rgb(255, 255, 255);
                          "
                          >“成为领先的公共安全服务专家”</span
                        ></strong
                      ></span
                    ><span
                      style="
                        letter-spacing: 0px;
                        background: rgb(255, 255, 255);
                      "
                      >为愿景，不断提升技术实力和服务水平。我们致力于通过科技创新，为更多的体育赛事和城市发展提供坚实的公共安全保障。</span
                    >
                  </p>
                  <p style="text-indent: 32px">
                    <span
                      style="
                        caret-color: red;
                        letter-spacing: 0px;
                        background-image: initial;
                        background-position: initial;
                        background-size: initial;
                        background-repeat: initial;
                        background-attachment: initial;
                        background-origin: initial;
                        background-clip: initial;
                      "
                      >我们相信，在</span
                    ><span
                      style="
                        color: rgb(3, 63, 206);
                        letter-spacing: normal;
                        line-height: 2em;
                      "
                      ><strong
                        ><span
                          style="
                            letter-spacing: 0px;
                            background: rgb(255, 255, 255);
                          "
                          >“共创、共享、共赢”</span
                        ></strong
                      ></span
                    ><span
                      style="
                        caret-color: red;
                        letter-spacing: 0px;
                        background-image: initial;
                        background-position: initial;
                        background-size: initial;
                        background-repeat: initial;
                        background-attachment: initial;
                        background-origin: initial;
                        background-clip: initial;
                      "
                      >的工作文化引领下，中码科技将实现与合作伙伴的紧密协作，共同开创更加安全、高效的体育赛事新篇章。未来的体育赛事将因我们的努力而更加精彩，而城市的公共安全也将因我们的贡献而更加稳固。</span
                    >
                  </p>
                  <p style="text-indent: 32px">
                    <span
                      style="
                        letter-spacing: 0px;
                        background: rgb(255, 255, 255);
                      "
                    ></span>
                  </p>
                  <p style="text-align: center"><br /></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </div>
</template>
