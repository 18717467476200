<template>
  <div
    class="rich_media_content js_underline_content defaultNoSetting"
    id="js_content"
    style="visibility: visible"
  >
    <section data-role="outer" label="edit by 135editor" style="visibility: visible">
      <section
        data-role="title"
        data-tools="135编辑器"
        data-id="122632"
        style="visibility: visible"
      >
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: center;
            visibility: visible;
          "
        >
          <section style="display: flex; justify-content: center; visibility: visible">
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 6px;
                  height: 100%;
                  background-color: rgb(45, 118, 191);
                  border-radius: 5px;
                  box-sizing: border-box;
                  overflow: hidden;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
            <section
              style="padding: 3px 5px; box-sizing: border-box; visibility: visible"
            >
              <section
                style="
                  font-size: 16px;
                  color: rgb(45, 118, 191);
                  text-align: center;
                  letter-spacing: 1.5px;
                  background-color: rgb(242, 247, 255);
                  padding: 5px 15px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <strong
                  data-brushtype="text"
                  style="
                    letter-spacing: 1.5px;
                    font-size: 16px;
                    line-height: 1.56em;
                    visibility: visible;
                  "
                  ><p style="visibility: visible">
                    <strong
                      data-brushtype="text"
                      style="
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        line-height: 1.56em;
                        visibility: visible;
                      "
                      >智慧核验系统助力展会安全快速通行</strong
                    >
                  </p></strong
                >
              </section>
            </section>
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 6px;
                  height: 100%;
                  background-color: rgb(45, 118, 191);
                  border-radius: 5px;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="125373" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: center;
            visibility: visible;
          "
        >
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              visibility: visible;
            "
          >
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: rgb(78, 132, 216);
                text-align: center;
                padding: 0px 10px;
                box-sizing: border-box;
                visibility: visible;
              "
            >
              <span style="font-size: 18px; visibility: visible"
                ><strong data-brushtype="text" style="visibility: visible"
                  >✦</strong
                ></span
              >
            </section>
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="text-indent: 37px; visibility: visible">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >中国国际警用装备博览会（下简称“警博会”）是唯一由公安部主办的警用装备展会，迄今已成功举办了十届。随着展会规模不断扩大，现已成为中国乃至亚太地区最具影响力、最权威的警用装备盛会，代表了中国警用装备行业的最高水平，汇聚了世界先进警用装备及技术，成为推动国内警用装备发展和促进国际警务交流合作的重要平台。</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: center;
            visibility: visible;
          "
        >
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              visibility: visible;
            "
          >
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: rgb(78, 132, 216);
                text-align: center;
                padding: 0px 10px;
                box-sizing: border-box;
                visibility: visible;
              "
            >
              <span style="font-size: 18px; visibility: visible"
                ><strong data-brushtype="text" style="visibility: visible"
                  >✦</strong
                ></span
              >
            </section>
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="text-indent: 37px; visibility: visible">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.57em;
              font-family: 微软雅黑, 'Microsoft YaHei';
              visibility: visible;
            "
            >第十一届警博会已于2023年第二季度在北京首钢会展中心（首钢园）举办，今年的警博会吸引了众多参展商。中码科技发展（成都）有限公司作为本次警博会的展会服务商，我们的可折叠立式核验机发挥出特点和优势，在展会期间提供了设备技术支持和人员管理服务，参展人员可提前预约，核验通行，有效避免通行阻塞，为展会的安全提供有力保障。</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373" style="visibility: visible">
        <section
          style="
            margin: 10px auto;
            display: flex;
            justify-content: center;
            visibility: visible;
          "
        >
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              visibility: visible;
            "
          >
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: rgb(78, 132, 216);
                text-align: center;
                padding: 0px 10px;
                box-sizing: border-box;
                visibility: visible;
              "
            >
              <span style="font-size: 18px; visibility: visible"
                ><strong data-brushtype="text" style="visibility: visible"
                  >✦</strong
                ></span
              >
            </section>
            <section style="flex-shrink: 0; visibility: visible">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: rgb(106, 180, 253);
                  overflow: hidden;
                  box-sizing: border-box;
                  visibility: visible;
                "
              >
                <br style="visibility: visible" />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
              visibility: visible;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid rgb(106, 180, 253);
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
                max-width: 100% !important;
                visibility: visible;
              "
              data-width="100%"
            >
              <br style="visibility: visible" />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph" style="visibility: visible">
        <p style="visibility: visible">
          <img
            class="rich_pages wxw-img"
            data-ratio="0.5965189873417721"
            data-src="@/assets/images/news/04/01.png"
            data-w="632"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              visibility: visible !important;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
            "
            data-index="2"
            src="@/assets/images/news/04/01.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-role="title" data-tools="135编辑器" data-id="125455">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section>
            <section
              style="display: flex; justify-content: flex-end; margin: 0 0 -10px 0"
            >
              <section
                style="
                  width: 15px;
                  height: 15px;
                  border-top: 4px solid #ffca42;
                  border-right: 4px solid #ffca42;
                  overflow: hidden;
                  box-sizing: border-box;
                  transform: translate(4px, 0px);
                  -webkit-transform: translate(4px, 0px);
                  -moz-transform: translate(4px, 0px);
                  -o-transform: translate(4px, 0px);
                "
              >
                <br />
              </section>
              <section data-role="title" data-tools="135编辑器" data-id="125388">
                <section
                  style="margin: 10px auto; display: flex; justify-content: center"
                >
                  <section
                    style="
                      font-size: 16px;
                      color: #4e84d8;
                      text-align: center;
                      letter-spacing: 1.5px;
                      border-top: 1px solid #6ab4fd;
                      padding: 3px 0;
                      box-sizing: border-box;
                    "
                  >
                    <span style="font-size: 14px"
                      ><strong data-brushtype="text"
                        >中国国际警用装备博览会现场</strong
                      ></span
                    >
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-indent: 37px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >可折叠立式核验机是一款集人脸1:1比对、1:N比对、活体防伪、证卡识读、重点关注人员核查等功能于一体的人脸识别设备。</span
          >
        </p>
        <p style="text-indent: 37px"><br /></p>
        <p style="text-indent: 37px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >设备采用六核高性能ARM处理器，专业ISP图像处理芯片，彩色+红外双目高清摄像头，人脸识别准确率大于99%；可以实现快速、准确、无接触的核验与登记，参观者只需进行扫码登记注册/身份证刷脸即可进入展会，真正做到有效性、证件真实性、人证同一性。</span
          >
        </p>
        <p><br /></p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.4609120521172638"
            data-src="@/assets/images/news/04/02.png"
            data-w="614"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="3"
            src="@/assets/images/news/04/02.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >设备支持无需携带身份证时进行人证核验。通过微信小程序(实我认证
            )注册网络身份证生成个人专属身份证二维码，在进行人证核验时出示专属二维码，设备可将二维码和现场抓拍活体人像提交至中华人民共和国公安部可信身份认证平台(
            CTID ) 进行核验，实现人证同一性验证。</span
          >
        </p>
        <p>
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >&nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;核验数据与全国关注人员数据进行碰撞，比中则返回核验人员的关注类别；实现与社会大联动，核查用户身份，预防安全隐患。</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.49128367670364503"
            data-src="@/assets/images/news/04/03.png"
            data-w="631"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="4"
            src="@/assets/images/news/04/03.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-indent: 2em">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >我们的可折叠立式核验机由前端智能核验设备与互联网、公安网两个中心管理平台组成，具有数据分析和管理功能，也可提供基于互联网SAAS平台的数据接入客户自己的系统，进行私有化部署及交付，做到数据统一治理、信息统一调度、人员统一管理，实现展会的智慧化和数字化。</span
          >
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p>
          <img
            class="rich_pages wxw-img"
            data-ratio="0.4114906832298137"
            data-src="@/assets/images/news/04/04.png"
            data-w="644"
            style="
              vertical-align: inherit;
              box-sizing: border-box;
              max-width: 100% !important;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-index="5"
            src="@/assets/images/news/04/04.png?wxfrom=5&amp;wx_lazy=1&amp;wx_co=1"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </p>
      </section>
      <section data-tools="135编辑器" data-id="125373">
        <section style="margin: 10px auto; display: flex; justify-content: center">
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
          <section
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
            "
          >
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
            <section
              style="
                font-size: 16px;
                color: #4e84d8;
                text-align: center;
                padding: 0 10px;
                box-sizing: border-box;
              "
            >
              <span style="font-size: 18px"
                ><strong data-brushtype="text">✦</strong></span
              >
            </section>
            <section style="flex-shrink: 0">
              <section
                style="
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background-color: #6ab4fd;
                  overflow: hidden;
                  box-sizing: border-box;
                "
              >
                <br />
              </section>
            </section>
          </section>
          <section
            style="
              width: 50%;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              max-width: 50% !important;
            "
            data-width="50%"
          >
            <section
              style="
                width: 100%;
                border-top: 1px solid #6ab4fd;
                max-width: 100% !important;
                height: 1px;
                overflow: hidden;
                box-sizing: border-box;
              "
              data-width="100%"
            >
              <br />
            </section>
          </section>
        </section>
      </section>
      <section data-role="paragraph">
        <p style="text-align: left; text-indent: 37px">
          <span
            style="
              font-size: 14px;
              letter-spacing: normal;
              line-height: 1.5em;
              font-family: 微软雅黑, 'Microsoft YaHei';
            "
            >在第十届中国国际警用装备博览会上，中码科技发展（成都）有限公司在展会期间全程提供技术支持和管理服务，确保设备的稳定运行和高效使用；可折叠立式核验机设备实时采集信息上传系统平台完成信息核查，为用户提供权威、可靠、稳定、安全的身份认证服务。</span
          >
        </p>
      </section>
    </section>
    
  </div>
</template>
